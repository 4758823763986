import React, { useState, useEffect, useRef } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import SearchSection from "../components/Relationship/SearchSection"
import RightSection from "../components/Relationship/RightSection"
import { getCategories, getCategorykeywordlist, getNonRelatedKeywords } from "../services/keywordsApis"
import RenderCategory from "../components/CommonComp/RenderCategory"
import { getKeywordsCateogyListing } from "../services/EnterNewKeyword"
import { computeLoaderState, findObjectInArray, findObjectIndexInArray, getArrayOfObjJoinToStringForKey } from "../lib/helper"
import { updateChildKeyword } from "../services/RelationshipApis"

function RelationshipScreen({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(2, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {
        <ChildComponent loading={loading} setLoading={setLoading}/>
      }
    </WindowCreater>
  )
}

export default RelationshipScreen

const ChildComponent = ({isMaximized, isMinimized, position, parentDimensions, loading, setLoading, windowRef}) => {
    const [resetData, setResetData] = useState(false)
    const [actualKeywords, setActualKeywords] = useState([])
    const [categoriesData, setCategoriesData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [parentCategories, setParentCategories] = useState([])
    const [selectedParent, setSelectedParent] = useState(null)
    const [parentChildCategory, setParentChildCategories] = useState([])
    const [selectedParentChild, setSelectedParentChild] = useState(null)
    const [selectedAllKeyword, setSelectedAllKeyword] = useState(null)
    const [notRelatedCategory, setNotRelatedCategory] = useState([])
    const [selectedNotRelatedCategory, setSelectedNotRelatedCategory] = useState(null)
    const [shouldRestScroll, setShouldRestScroll] = useState(false)
    const [selectedWords, setSelectedWords] = useState("")
    const parentKeywordListRef = useRef(null);
    const allKeywordListRef = useRef(null);

    const [allKeywordData, setAllKeywordData] = useState([])

    async function fetchCategory() {
      setLoading({
        ...loading,
        submit: true
      })
      let data = await getCategories()
      if(data?.length){
        let newData = data?.filter((el)=>el?.category_id !== 3)?.map((el)=>{
          return {
            label: el?.category_name,
            value: el?.category_id,
            item: el
          }
        })
        setCategoriesData(newData);
      }
      setLoading({
        ...loading,
        submit: false
      })
    }
    async function fetchNonRelatedKeywords() {
      let data = await getNonRelatedKeywords()
      if(data?.length){
        setNotRelatedCategory(data);
      }
    }

    async function fetchSelectedCategory(payload) {
      let data = await getKeywordsCateogyListing(payload)
      setAllKeywordData(data)
    }

    async function fetchSelectedCategoryCategory(id, parent_name=undefined) {
      if(id){
        let data = await getCategorykeywordlist({
          category_id: id,
          parent_name: parent_name
        })
        return data
      }
    }
  
    const saveChanges = async ()=>{
      const checkAllKeywords = allKeywordData?.filter((el)=>{
        return el?.isChecked
      })
      if(selectedParent?.parent_name){
        setLoading({
          ...loading,
          submit: true
        })
        let resp = await updateChildKeyword({
          parent_name : selectedParent?.parent_name,
          child_name: getArrayOfObjJoinToStringForKey(checkAllKeywords, 'visible_keyword', ','),
          category_name: selectedCategory?.label
        })

        if(resp){
          fetchNonRelatedKeywords()
          let dataNew = await fetchSelectedCategoryCategory(
            selectedCategory?.value,
            selectedParent?.parent_name
          )
          let updateAllKeyword = [...allKeywordData]
          updateAllKeyword = updateAllKeyword?.map((el, ind)=>{
            return {
              ...el,
              isChecked: false
            }
          })
          dataNew?.map((ele, ind)=>{
            let objInd = findObjectIndexInArray(updateAllKeyword, "visible_keyword", ele?.child_name)
            if(objInd > -1){
              updateAllKeyword[objInd]["isChecked"] = true
            }
          })
          setAllKeywordData(updateAllKeyword)
          setParentChildCategories(dataNew)
          setSelectedParentChild(null)
          setShouldRestScroll(true)
          setSelectedAllKeyword(null)
        }
        setLoading({
          ...loading,
          submit: false
        })
      }
    }
   
  
    const handleCategoryChange = async(data)=>{
      setSelectedCategory(data)
      let dataNew = await fetchSelectedCategoryCategory(data?.value)
      setParentCategories(dataNew)
      setSelectedParent(null)
      setSelectedParentChild(null)
      setParentChildCategories([])
      let updateAllKeyword = [...allKeywordData]
      updateAllKeyword = updateAllKeyword?.map((el, ind)=>{
        return {
          ...el,
          isChecked: false
        }
      })
      setAllKeywordData(updateAllKeyword)
      // await fetchSelectedCategory({category_id: 3})
    }
   
    useEffect(()=>{
      fetchCategory()
      fetchNonRelatedKeywords()
      fetchSelectedCategory({category_id: 3})
    },[])

    useEffect(()=>{
      if(resetData){
        setParentCategories([])
        setParentChildCategories([])
        setSelectedParentChild(null)
        setSelectedParent(null)
        setSelectedCategory([])
      }
    },[resetData])

    const handleChildListClick = (el, index=0, key="visible_keyword", ref=null) => {
      try{
        const findIndex = findObjectIndexInArray(allKeywordData, 'visible_keyword', el[key])
        let elRef = ref?.current
        if(elRef){
          elRef?.scrollToIndex({
            index: findIndex,
            align: "end",
            behavior: "auto"
          });
          setSelectedAllKeyword(allKeywordData[findIndex])
        }
      }catch(e){}

    };
    const handleChildListClick2 = (el, index=0, key="visible_keyword", ref=null) => {
      try{
        const findIndex = findObjectIndexInArray(allKeywordData, 'visible_keyword', el[key])
        let elRef = ref?.current
        if(elRef){
          elRef?.scrollToIndex({
            index: findIndex,
            align: "end",
            behavior: "auto"
          });
          setSelectedAllKeyword(allKeywordData[findIndex])
        }
        let data = [...allKeywordData]
        data[findIndex]['isChecked'] = (data[findIndex]['isChecked'])?data[findIndex]['isChecked'] = false:data[findIndex]['isChecked']=true;
        setAllKeywordData(data);
      }catch(e){}

    };
    return <div className="container-child-wrapper row">
      <div className="col-9 d-flex flex-column">
        <div>
          <SearchSection
            setActualKeywords={setActualKeywords}
            resetData={resetData}
            categoriesData={categoriesData}
            setResetData={setResetData}
            handleCategoryChange={handleCategoryChange}
            selectedCategory={selectedCategory}
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
            windowRef={windowRef}
            saveChanges={saveChanges}
          />
        </div>
        <div className="row g-2 h-100" id="keyword-list-container" style={{ flex: 1 }}>
          <RenderCategory
            categories={parentCategories}
            ref={parentKeywordListRef}
            selectedCategory={selectedCategory}
            selected={selectedParent}
            labelHeading="Category Keywords"
            onClick={async (el, index, ref) => {
              setSelectedParent(el)
              let dataNew = await fetchSelectedCategoryCategory(
                selectedCategory?.value,
                el?.parent_name
              )
              let updateAllKeyword = [...allKeywordData]
              updateAllKeyword = updateAllKeyword?.map((el, ind)=>{
                return {
                  ...el,
                  isChecked: false
                }
              })
              dataNew?.map((ele, ind)=>{
                let objInd = findObjectIndexInArray(updateAllKeyword, "visible_keyword", ele?.child_name)
                if(objInd > -1){
                  updateAllKeyword[objInd]["isChecked"] = true
                }
              })
              setAllKeywordData(updateAllKeyword)
              setParentChildCategories(dataNew)
              setSelectedParentChild(null)
              setShouldRestScroll(true)
              setSelectedAllKeyword(null)
            }}
            objAccessKey={"parent_name"}
            shouldRestScroll={shouldRestScroll}
            setShouldRestScroll={setShouldRestScroll}
            id="parent"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
          <RenderCategory
            categories={allKeywordData}
            selectedCategory={selectedCategory}
            ref={allKeywordListRef}
            selected={selectedAllKeyword}
            labelHeading="Only Keywords"
            onClick={(el, index, ref) => {
              let data = [...allKeywordData]
              const findIndex = findObjectIndexInArray(data, 'visible_keyword', el["visible_keyword"])
              data[findIndex]['isChecked'] = data[findIndex]['isChecked'] ? !data[findIndex]['isChecked'] : true
              setAllKeywordData(data)
              setSelectedAllKeyword(data[findIndex])
            }}
            onCheckboxClick={(e, el, index) => {}}
            enableCheckBox={true}
            objAccessKey={"visible_keyword"}
            accessKeyValue={'keyword_id'}
            id="allKeyword"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
          <RenderCategory
            categories={parentChildCategory}
            selectedCategory={selectedCategory}
            selected={selectedParentChild}
            labelHeading="Already Related Keywords"
            onClick={( el, index, ref) => {
              setSelectedParentChild(el)
              handleChildListClick(el, index, "child_name", allKeywordListRef)
            }}
            doubleClickFunctionality={(el, index, ref)=>{
              handleChildListClick2(el, index, "child_name", allKeywordListRef)
            }}
            objAccessKey={"child_name"}
            id="child"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
          <RenderCategory
            categories={notRelatedCategory}
            selectedCategory={selectedCategory}
            selected={selectedNotRelatedCategory}
            labelHeading="Non-Related Keywords"
            onClick={( el, index, ref) => {
              setSelectedNotRelatedCategory(el)
              handleChildListClick(el, index, "visible_keyword", allKeywordListRef)
            }}
            doubleClickFunctionality={(el, index, ref)=>{
              handleChildListClick2(el, index, "visible_keyword", allKeywordListRef)
            }}
            objAccessKey={"visible_keyword"}
            accessKeyValue={'visible_keyword'}
            id="non-related-keyword"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
        </div>
      </div>

      <div className="col-3 d-flex flex-column">
        <RightSection
          actualKeywords={actualKeywords}
          selectedCategory={selectedCategory}
          selectedWords={selectedWords}
          setSelectedWords={setSelectedWords}
          setActualKeywords={setActualKeywords}
          isMaximized={isMaximized}
          isMinimized={isMinimized}
          position={position}
          parentDimensions={parentDimensions}
          loading={loading} 
          setLoading={setLoading}
          windowRef={windowRef}
        />
      </div>
    </div>
}