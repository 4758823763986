import React, { useState, useEffect, useRef } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import SelectedResultBox from "../components/ImageKeywording/SelectedResultBox"
import SearchSection from "../components/ImageKeywording/SearchSection"
import RightSection from "../components/ImageKeywording/RightSection"
import { getCategories, getCategorykeywordlist, postImageKeywords } from "../services/keywordsApis"
import RenderCategory from "../components/CommonComp/RenderCategory"
import { computeLoaderState } from "../lib/helper"
import MessageDialog from "../components/CommonComp/modals/MessageDialog"

function ImageKeywording({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(3, false)
        let imageContainer = document.getElementById('imagePreviewWindowContainer');
        imageContainer.style.display = '';
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {
        <ChildComponent  loading={loading} setLoading={setLoading} />
      }
    </WindowCreater>
  )
}

export default ImageKeywording

const ChildComponent = ({isMaximized, isMinimized, position, parentDimensions, loading, setLoading, windowRef}) => {
    const [resetData, setResetData] = useState(false)
    const [selectedResults, setSelectedResults] = useState([])
    const [actualKeywords, setActualKeywords] = useState([])
    const [categoriesData, setCategoriesData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])
    const [parentCategories, setParentCategories] = useState([])
    const [selectedParent, setSelectedParent] = useState(null)
    const [parentChildCategory, setParentChildCategories] = useState([])
    const [selectedParentChild, setSelectedParentChild] = useState(null)
    const [shouldRestScroll, setShouldRestScroll] = useState(false)
    const [selectedWords, setSelectedWords] = useState("")
    const [messageOpen, setMessageOpen] = useState({
      open: false,
      message: "",
    })
  
    const [captionData, setCaptionData] = useState({
      enable: false,
      data: ''
    })
  
    const [selectedFile, setSelectedFile] = useState(null)
    const [blobURL, setBlobURL] = useState(null)
    const [allFiles, setAllFiles] = useState(null)
    const { handleMenu } = useAppContext()
    const [isPresentCounter, setIsPresentCounter] = useState(0);
    const [selectedResultFocus, setSelectedResultFocus] = useState(false);
    const handleFileChange = (event) => {
      let files = Object.values(event.target.files)
      files = files.filter((el, i)=>{ return el.name !== '.DS_Store'})
      setAllFiles(files)
    }
    const removeImageFromList = (fileToRemove) =>{
      let fileList = [...allFiles];
      fileList.forEach((listItem, itemIndex)=>{
        if(listItem?.name == fileToRemove){
          fileList.splice(itemIndex, 1);
        }
      })
      setAllFiles(fileList);
    }
    const handleSelectedFile = (file) => {
      // Create a Blob URL from the selected file
      setSelectedFile(file)
      const fileBlobURL = URL.createObjectURL(file)
      setBlobURL(fileBlobURL)
    }
    
    const clearImageField = () => {
      // Create a Blob URL from the selected file
      setSelectedFile(null)
      setBlobURL(null)
    }
  
    async function fetchCategory() {
      setLoading({
        ...loading,
        submit: true
      })
      let data = await getCategories()
      if(data?.length){
        let newData = data?.filter((el)=>el?.category_id !== 3)?.map((el)=>{
          return {
            label: el?.category_name,
            value: el?.category_id,
            item: el
          }
        })
        setCategoriesData(newData);
      }
      setLoading({
        ...loading,
        submit: false
      })
    }
    async function fetchSelectedCategoryCategory(id, parent_name=undefined) {
      if(id){
        let data = await getCategorykeywordlist({
          category_id: id,
          parent_name: parent_name
        })
        return data
      }
    }
  
    const handleCategoryChange = async(data)=>{
      setLoading({
        ...loading,
        submit: true
      })
      setSelectedCategory(data)
      let dataNew = await fetchSelectedCategoryCategory(data?.value)
      setParentCategories(dataNew)
      setSelectedParent(null)
      setSelectedParentChild(null)
      setParentChildCategories([])
      setLoading({
        ...loading,
        submit: false
      })
    }
    
    useEffect(()=>{
      fetchCategory()
    },[])
    useEffect(()=>{
      if(resetData){
        setParentCategories([])
        setParentChildCategories([])
        setSelectedParentChild(null)
        setSelectedParent(null)
        setSelectedCategory([])
        setSelectedFile(null)
        setCaptionData({
          ...captionData,
          data: ''
        })
      }
    },[resetData])
    const isSelectedResultInclude = () =>{
      let isPresent = false;
      for(let i=0; i<selectedResults.length; i++){
        if(selectedWords.includes(selectedResults[i])){
          isPresent = true;
        }
        else{
          isPresent = false;
        }
      }
      return isPresent;
    }
    const handleSubmit = async(updateImage) => {
      if(selectedFile && selectedWords ){
        if(isPresentCounter < 1){
          let isPresent = isSelectedResultInclude();
          if(isPresent !== true && selectedResults.length > 0){
            setMessageOpen({
              open: true,
              message: "Please click on submit!",
              props: {
                okClick:()=>{
                  setMessageOpen({
                    ...messageOpen,
                    open: false
                  })
                }
              }
            })
            setIsPresentCounter(1);
            return;
          }
          // else{
          //   setIsPresentCounter(1);
          // }
        }
        
        setLoading({
          ...loading,
          submit: true
        })
        
        let pathArr = selectedFile?.webkitRelativePath?.split("/")
        let selectedWordsWithoutSpace = selectedWords.replace(/,\s+/g, ',');
        let formData = new FormData();

        formData.append("shoot_id", pathArr[0])
        formData.append("image", pathArr[1]?.split(".")[0])
        formData.append("keywords", selectedWordsWithoutSpace)
        formData.append("caption", captionData?.data)
        formData.append("uploaded_file", selectedFile);
        if(updateImage == 'yes'){
          formData.append("updateimage", updateImage);
        }
        
        let resp = await postImageKeywords(formData)
        if(resp?.status && resp?.message == "Image Inserted Successfully"){
          setActualKeywords([])
          setSelectedWords('')
          setCaptionData({
            ...captionData,
            data: ''
          })
          // setSelectedFile(null);  
        }
        else if(resp?.status && resp?.message == "Images Already Exist"){
          setMessageOpen({
            open: true,
            message: "Image already exist! Do you want to change the image?",
            props: {
              okClick:()=>{
                handleSubmit('yes');
                setMessageOpen({
                  ...messageOpen,
                  open: false
                })
              },
              cancelClick:()=>{
                setMessageOpen({
                  ...messageOpen,
                  open: false
                })
              }
            }
          })
        }
        else{
          setMessageOpen({
            open: true,
            message: resp.message,
            props: {
              okClick:()=>{
                setMessageOpen({
                  ...messageOpen,
                  open: false
                })
              }
            }
          })
        }
        // setResetData(true)
        setLoading({
          ...loading,
          submit: false
        })
      }else{
        alert('Please Select An Image')
        setLoading({
          ...loading,
          submit: false
        })
      }
    }

    const selectResultBoxRef = useRef(null);
    useEffect(()=>{
      if(selectedResultFocus == true && selectedResults.length !=0){
        const handleKeyDown = (e) =>{
          isValidKey(e.key);
        }
        window.addEventListener('keydown', handleKeyDown);
        return () =>{
          window.removeEventListener('keydown', handleKeyDown);
        }
      }
    },[selectedResultFocus, selectedResults]);
    const isValidKey = (key) => { 
      const regExpression = new RegExp(/^([a-z0-9])$/); 
      let isValidKey = regExpression.test(key);
      if(selectedResultFocus && isValidKey == true){
        filterListAsPerKey(key);  
      }
    }
    const [selectedItem, setSelectedItem] = useState('');
    const filterListAsPerKey = (key) =>{
      let keyWordList = [];
      let itemList = document.getElementsByClassName('srb-item');
      let selectedItemInfo = {
        itemName: null,
        itemIndex: null
      };

      for(let i = 0; i < itemList.length; i++){
        if(itemList[i].className.includes('focused-li')){
          selectedItemInfo.itemName = itemList[i].innerText;
          selectedItemInfo.itemIndex = i;
        }
      }
      selectedResults.forEach((element, index) => {
        let keyword = element;
        if(keyword.startsWith(key.toUpperCase())){
          keyWordList.push(element);
        }
      });
      // keyWordList.sort();
      let selectedItemIndexInList = keyWordList.indexOf(selectedItemInfo.itemName);
      
      if(selectedItemIndexInList > -1){
        let newItemIndex = (selectedItemIndexInList + 1);
        if(newItemIndex > (keyWordList.length - 1)){
          newItemIndex = 0;
          setSelectedItem(keyWordList[newItemIndex]);
        }else{
          setSelectedItem(keyWordList[newItemIndex]);
        }

      }else{
        let newItemIndex = 0;
        setSelectedItem(keyWordList[newItemIndex]);
      }
      
    }
    useEffect(()=>{selectNewItemAsPerKey(selectedItem)},[selectedItem]);
    function selectNewItemAsPerKey(newItemName){
      let itemList = document.getElementsByClassName('srb-item');
      let focusedItemScrollPosition = null;
      for(let i=0; i<itemList.length; i++){
        itemList[i].classList.remove('focused-li');
      }
      for(let i=0; i<itemList.length; i++){
        if((itemList[i].innerText)?.trim() == (newItemName)?.trim()){
          itemList[i].classList.add('focused-li');
          focusedItemScrollPosition = itemList[i].offsetTop;
        }
      }
      selectResultBoxRef.current.scrollTop = focusedItemScrollPosition;
    }
    return <div className="container-child-wrapper row">
      <div className="col-6 d-flex flex-column">
        <div>
          <SearchSection
            setActualKeywords={setActualKeywords}
            selectedResults={selectedResults}
            setSelectedResults={setSelectedResults}
            resetData={resetData}
            categoriesData={categoriesData}
            setResetData={setResetData}
            handleCategoryChange={handleCategoryChange}
            selectedCategory={selectedCategory}
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
            windowRef={windowRef}
            onClick={()=>{setSelectedResultFocus(false)}}
          />
        </div>
        <div className="row g-2 h-100" id="keyword-list-container" style={{ flex: 1 }}>
          <RenderCategory
            categories={parentCategories}
            selected={selectedParent}
            onClick={async (el, index, ref) => {
              setSelectedResultFocus(false)
              setSelectedParent(el)
              let dataNew = await fetchSelectedCategoryCategory(
                selectedCategory?.value,
                el?.parent_name
              )
              setParentChildCategories(dataNew)
              setSelectedParentChild(null)
              setShouldRestScroll(true)
              if (!selectedResults?.includes(el?.parent_name) && selectedCategory?.value === 2) {
                setSelectedResults((prevSelectedResults) => [
                  ...prevSelectedResults,
                  el?.parent_name
                ])
              }
              
            }
            
          }
            objAccessKey={"parent_name"}
            accessKeyValue={"category_keyword_id"}
            shouldRestScroll={shouldRestScroll}
            setShouldRestScroll={setShouldRestScroll}
            id="parent"
            parentClassname="col-4"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
          <RenderCategory
            categories={parentChildCategory}
            selected={selectedParentChild}
            onClick={(el, index, ref) => {
              setSelectedResultFocus(false)
              setSelectedParentChild(el)
  
              if (!selectedResults?.includes(el?.child_name)) {
                setSelectedResults((prevSelectedResults) => [
                  ...prevSelectedResults,
                  el?.child_name
                ])
              }
            }}
            objAccessKey={"child_name"}
            id="child"
            parentClassname="col-4"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
          <SelectedResultBox
            selectedResults={selectedResults}
            setSelectedResults={setSelectedResults}
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
            setSelectedResultFocus = {setSelectedResultFocus}
            ref={selectResultBoxRef}
          />
        </div>
        <div className="text-end mt-2">
          <button
            type="button"
            className="btn btn-sm btn-secondary me-2"
            onClick={() => {
              setSelectedResultFocus(false)
              setSelectedResults([])
              setActualKeywords([])
              setSelectedWords("")
              setParentChildCategories([])
              setSelectedParentChild(null)
              setSelectedParent([])
              setResetData(true)
            }}
          >
            Reset All
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary me-2"
            onClick={() => {
              setSelectedResultFocus(false)
              setSelectedResults([])
              // setActualKeywords([])
              // setResetData(true)
            }}
          >
            Reset Selected Options
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => {
              // setSelectedResults([])
              // setActualKeywords([])
              // setResetData(true)
              handleSubmit()
            }}
          >
            Save
          </button>
        </div>
      </div>
      <div className="col-6 d-flex flex-column">
        <RightSection
          actualKeywords={actualKeywords}
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          selectedWords={selectedWords}
          setSelectedWords={setSelectedWords}
          blobURL={blobURL}
          setActualKeywords={setActualKeywords}
          handleSelectedFile={handleSelectedFile}
          clearImageField={clearImageField}
          setPresentCounter={setIsPresentCounter}
          allFiles={allFiles}
          captionData={captionData}
          setCaptionData={setCaptionData}
          isMaximized={isMaximized}
          isMinimized={isMinimized}
          position={position}
          parentDimensions={parentDimensions}
          windowRef={windowRef}
          removeImageFromList={removeImageFromList}
          onClick={()=>{setSelectedResultFocus(false)}}
        />
      </div>
      {messageOpen?.open && <MessageDialog messageHeading="Message" message={messageOpen?.message} {...messageOpen?.props}/>}
    </div>
}