import React, { useState, useRef, useEffect } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import Input from "../components/CommonComp/Input"
import Select from '../components/CommonComp/Select';
import { exportImagesApi } from "../services/ExportDataApis"
import { computeLoaderState, downloadBlobXLSFile } from "../lib/helper"

const options = [
  {label: 'Folder No.', value: 'shoot_id'},
  {label: 'Image Name', value: 'image_id'},
]

function ExportXls({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(1, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {<ChildComponent loading={loading} setLoading={setLoading} />}
    </WindowCreater>
  )
}

export default ExportXls

const ChildComponent = ({isMaximized, loading, setLoading}) => {
  const [selectedFolder, setSelectedFolder] = useState({label: 'Folder No.', value: 'shoot_id'})
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const { handleMenu } = useAppContext()
  const handleFolderChange = (e) => {
    setSelectedFolder(e)
  }

  const handleSubmit = async (e) => {
    if(selectedFolder && from && to){
      setLoading({
        ...loading,
        submit: true
      })
      let resp = await exportImagesApi({
        type: selectedFolder?.value,
        from: from,
        to: to,
      })
      if(resp){
        downloadBlobXLSFile(resp, selectedFolder?.value + "-" + from + '-' + to)
      }
      setLoading({
        ...loading,
        submit: false
      })
    }
  }
  
  return (
    <div className="container-child-wrapper">
        <div className="row">
          <div className="col-sm-4">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Search Type</div>
            <div>
              <Select 
                  options={options} 
                  mainContainerProps={{
                      className: 'd-flex m-0 ms-1'
                  }}
                  value={selectedFolder}
                  onChange={handleFolderChange}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>From</div>
            <div>
              <Input 
                value={from}
                onChange={(e)=>{
                    setFrom(e.target.value)
                }}
                onBlur={()=>{
                  let str = from.replace(/\s+/g,' ').trim();
                  setFrom(str)
                }}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>To</div>
            <div>
              <Input 
                value={to}
                onChange={(e)=>{
                    setTo(e.target.value)
                }}
                onBlur={()=>{
                  let str = to.replace(/\s+/g,' ').trim();
                  setTo(str)
                }}
              />
            </div>
          </div>
          <div className="col-sm-12 d-flex justify-content-end mt-5 ">
            <button
              className="btn btn-sm btn-secondary me-2"
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </button>
            <br />
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                handleMenu(1, false)
              }}
            >
              Exit
            </button>
          </div>
        </div>
    </div>
  )
}
