import React, { useState, useRef, useEffect } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import { advanceSearch, deleteSelectedImages, getRangeImagelistFolders, getShootFolders } from "../services/SearchScreenApis"
import FolderSection from "../components/SearchScreen/FolderSection"
import ImagesContainer from "../components/SearchScreen/ImagesContainer"
import KeywordContainer from "../components/SearchScreen/KeywordContainer"
import ImageRange from "../components/SearchScreen/ImageRange"
import FiltersSection from "../components/SearchScreen/FiltersSection"
import WordManupulationSection from "../components/SearchScreen/WordManupulationSection"
import DynamicScrollContent from "../components/HOC/DynamicScrollContent"
import { computeLoaderState } from "../lib/helper"
import AdvanceSearch from "../components/SearchScreen/AdvanceSearch"
import ConfirmDialog from "../components/CommonComp/modals/ConfirmDialog"
import { getKeywordsCateogyListing } from "../services/EnterNewKeyword"
import ImageDialog from "../components/CommonComp/modals/ImageDialog"
export const getArrayOfObjJoinToStringForKey = (arr, key, joinKey = ", ") => {
  return arr?.map((u) => u[key])?.join(joinKey) || ""
}
function SearchScreen({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  const [imagesCount, setImagesCount] = useState(0);
  let customTitle = <div><span className="d-inline-block w-25">{itemContainer.title}</span><p className="text-center d-inline-block w-50 mb-0">Total Images: {imagesCount}</p></div>
  return (
    <WindowCreater
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(4, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
      title={customTitle}
    >
      {<ChildComponent loading={loading} setLoading={setLoading} setImagesCount={setImagesCount}/>}
    </WindowCreater>
  )
}

export default SearchScreen

const ChildComponent = ({isMaximized, loading, setLoading,setImagesCount}) => {
  const [folderSection, setFolderSection] = useState([])
  const [selectedFolder, setSelectedFolder] = useState([])
  const [imagesFolder, setImagesFolder] = useState([])
  const [imageFrom, setImageFrom] = useState('')
  const [imageTo, setImageTo] = useState('')
  const [multiplePopupList, setMultiplePopupList] = useState([])
  const [imagesSelected, setImagesSelected] = useState([])
  const [advanceSearchData, setAdvanceSearchData] = useState([])
  const [fetchKeywordsStatus, setFetchKeywordsStatus] = useState(true)
  const [selectedAdvanceKeyword, setSelectedAdvanceKeyword] = useState(null)
  const [allKeywordForSelect, setAllKeywordForSelect] = useState([]);
  const [open, setOpen] = useState(false)
  const [multiAddDialog, setMultiAddDialog] = useState({
    open: false,
    confirm: false,
  })
  const [multiRemoveDialog, setMultiRemoveDialog] = useState({
    open: false,
    confirm: false,
  })
  const [wordManipulationDialog, setWordManipulationDialog] = useState({
    open: false,
    confirm: false,
    action: '',
    message: ''
  });
  useEffect(()=>{
    if(wordManipulationDialog.open || multiAddDialog.open || multiRemoveDialog.open){
      let dialogElement = document.getElementById('confirmationDialog');
      let submitBtn = dialogElement.querySelector('.confirm-SubmitBtn');
      submitBtn.focus();
    }
    
  },[wordManipulationDialog, multiAddDialog, multiRemoveDialog])
  const [checkbox, setCheckbox] = useState({
    notExist: false,
    multipleKeywordList: false,
    popupAnd: true,
    paopupOr: false,
  })
  const [actualWordInput, setActualWordInput] = useState(null)
  useEffect(()=>{
    if(selectedAdvanceKeyword){
      let filteredItem = allKeywordForSelect.filter((item)=>{
        if(item.value == selectedAdvanceKeyword.keyword){
          return item;
        }
      });
      setActualWordInput(filteredItem[0]);
    }
  },[selectedAdvanceKeyword])
  async function fetchFolders() {
    let haveId = false
    setLoading({
      ...loading,
      submit: true
    })
    let data = await getShootFolders()
    let newData = data?.map((el) => {
      if(el.shoot_id == selectedFolder?.value){
        haveId = true
      }
      return {
        label: el?.shoot_id,
        value: el?.shoot_id,
        item: el,
      }
    })
   
    if(!haveId){
      setSelectedFolder(null)
    }
    setFolderSection(newData)
    setLoading({
      ...loading,
      submit: false
    })
  }
  
  async function fetchShootImagelistFolders(payload) {
    setImagesFolder([]);
    setLoading({
      ...loading,
      submit: true
    })
    let data = await getRangeImagelistFolders(payload)
    if (data?.length) {
      setImagesFolder(data);
      setImagesSelected([]);
    }else{
      setImagesFolder([])
    }
    setLoading({
      ...loading,
      submit: false
    })
  }

  async function fetchSelectedCategory(payload) {
    let data = await getKeywordsCateogyListing(payload)
    setAllKeywordForSelect(data)
    let newData = data?.map((el)=>{
      return {
        label: el?.visible_keyword,
        value: el?.visible_keyword,
        item: el
      }
    })
    setAllKeywordForSelect(newData)
  }

  useEffect(() => {
    fetchFolders()
    fetchSelectedCategory({category_id: 3})
  }, [])

  const handleFolderChange = (e) => {
    setSelectedFolder(e)
    
  }
  const handleSearchSubmit = (from, to) => {
    fetchShootImagelistFolders({
      ...(imageFrom && {from: from}),
      ...(imageTo && {to: to})
    })
  }
  const handleFilterSubmit = (from, to) => {
    fetchShootImagelistFolders({
        shoot_id: selectedFolder?.value,
        not_exist: checkbox?.notExist ? 'true': 'false',
        multi_keyword_condition: checkbox?.multipleKeywordList ? checkbox?.popupAnd ? 'and' : checkbox?.paopupOr ? 'or' : undefined : undefined,
        search_keyword: checkbox?.multipleKeywordList ? multiplePopupList?.join(',') : actualWordInput?.value
    })
  }
  const handleImageFolder = async (imgIds) => {
    let data = [...imagesFolder]?.filter((el)=>{
      return !imgIds?.includes(el?.image_name)
    })
    setImagesFolder(data)
    setImagesSelected([])
    setFetchKeywordsStatus({
      status: true,
      from: "reset"
    })
    await fetchFolders()
  }

  const handleMultiplePopupData = (data, type='insert') => {
    if(multiplePopupList.indexOf(data[0]) === -1 && type === 'insert'){
      setMultiplePopupList([ ...multiplePopupList ,data[0]])
    }
    if(type === 'clear'){
      setMultiplePopupList([])
    }
  }
  const handleCheckBox = (e, type=null) => {
    setCheckbox({
      ...checkbox,
      ...(type === 1 && {notExist: e.target.checked}),
      ...(type === 2 && {multipleKeywordList: e.target.checked,
        popupAnd: true, 
        paopupOr: false
      }),
      ...(type === 3 && {popupAnd: e.target.checked, ...(e.target.checked && {paopupOr: !e.target.checked})}),
      ...(type === 4 && {paopupOr: e.target.checked, ...(e.target.checked && {popupAnd: !e.target.checked})}),
    })
  }

  const handleImageClick = (items) => {
    // let data = [item]
    // const arrayUniqueByKey = [...new Map(data.map(item =>
    //   [item['image_name'], item])).values()];
    
    setImagesSelected(items)
    setFetchKeywordsStatus({
      status: true,
      from: "normal"
    })
  }

  const [viewImage, setViewImage] = useState(false);
  const [imageToPreview, setImageToPreview] = useState(null);

  const handleImageDoubleClick = (selectedImage) =>{
    setViewImage(true);
    setImageToPreview(selectedImage);
  }
  
  const handleAdvanceSearchClick= async(data)=>{
    if(data){
      let resp = await advanceSearch({
        shoot_id: data?.value
      })
      if(resp?.length){
        let arr = resp.map((item, index)=>{
          let d = item?.split('$$');
          return {
            keyword: d[0],
            count: d[1],
            label: `${d[0]} ${d[1]}`
          }
        })
        setAdvanceSearchData(arr);
      } else {
        setAdvanceSearchData([]);
      }
    }else{
      setAdvanceSearchData([]);
    }
  }
  
  const handleClose = () =>{
    setAdvanceSearchData([]);
    setSelectedAdvanceKeyword(null)
    setActualWordInput(null)
    fetchShootImagelistFolders({
      shoot_id: selectedFolder?.value
    })
  }

  const handleSelectedAdvanceKeyword = (data) =>{
    setSelectedAdvanceKeyword(data)
    if(data){
      fetchShootImagelistFolders({
        shoot_id: selectedFolder?.value,
        not_exist: checkbox?.notExist ? 'true': 'false',
        search_keyword: data.keyword
      })
      // setActualWordInput(data.keyword)
    }
  }

  const handleDeleteImages = async (imagesSelected=[]) => {
    if(imagesSelected?.length){
      setLoading({
        ...loading,
        submit: true
      })
      let resp = await deleteSelectedImages({
        image: imagesSelected?.join(',')
      });
      setFetchKeywordsStatus({
        status: false,
        from: "normal"
      })
      if(resp){
        handleImageFolder(imagesSelected)
      }
      setLoading({
        ...loading,
        submit: false
      })
    }
  }

  const handleComfirmClose = (data) => {
    setOpen(data)
  }

  const handleConfirmSuccess = (data) => {
    setOpen(data)
    handleDeleteImages(imagesSelected)
  }
  useEffect(()=>{
    if(imagesFolder){
      setImagesCount(imagesFolder.length);
    }
  },[imagesFolder]);

  const  filterList = (option, rawInput) => {
    if(option && option.value.toUpperCase().startsWith(rawInput.toUpperCase())){
      return option;
    }
  }
  return (
    <div className="container-child-wrapper" style={{ display: "flex" }}>
      <div className="d-flex flex-column" style={{ flex: "0.25" }}>
        <DynamicScrollContent outerContainerStyle={{flex: 1}} outerContainerProps={{id: 'searchSectionMain'}} internalContainerProps={{
          className: 'border-1 user-select-none-all-child',
        }}>
          <FolderSection folderData={folderSection} selectedFolder={selectedFolder} handleFolderChange={handleFolderChange} 
            handleRefresh={async (e)=>{
              await fetchShootImagelistFolders({
                shoot_id: e?.label
              })
              await fetchFolders()
            }} handleGoClick={(e)=>{
              fetchShootImagelistFolders({
                shoot_id: e?.label
              })
            }}
            handleAdvanceSearchClick={handleAdvanceSearchClick}
          />
          {!!advanceSearchData?.length && <AdvanceSearch advanceSearchData={advanceSearchData} handleClose={handleClose}
            selectedAdvanceKeyword={selectedAdvanceKeyword} setSelectedAdvanceKeyword={selectedAdvanceKeyword}
            handleSelectedAdvanceKeyword={handleSelectedAdvanceKeyword}
          />}
          <ImageRange imageFrom={imageFrom} setImageFrom={setImageFrom} imageTo={imageTo}
            setImageTo={setImageTo} handleSearchSubmit={handleSearchSubmit}
            folderName={imagesFolder?.length === 1 ? imagesFolder[0]?.shoot_id : null}  
          />

          <FiltersSection checkbox={checkbox} handleCheckBox={handleCheckBox}
            actualWordInput={actualWordInput} setActualWordInput={setActualWordInput}
            handleFilterSubmit={handleFilterSubmit} handleMultiplePopupData={handleMultiplePopupData} 
            multiplePopupList={multiplePopupList} isMaximized={isMaximized}
            setMultiplePopupList={setMultiplePopupList}
            imagesSelected={imagesSelected} setFetchKeywordsStatus={setFetchKeywordsStatus}
            loading={loading} setLoading={setLoading} allKeywordForSelect={allKeywordForSelect}
            multiAddResponse={multiAddDialog}
            setMultiAddResponse={setMultiAddDialog} 
            multiRemoveResponse={multiRemoveDialog}
            setMultiRemoveResponse={setMultiRemoveDialog}
            filterFunction={filterList}
          />

          <WordManupulationSection allKeywordForSelect={allKeywordForSelect} imagesSelected={imagesSelected} setFetchKeywordsStatus={setFetchKeywordsStatus}
            handleImageFolder={handleImageFolder}  loading={loading} setLoading={setLoading}
            handleDeleteImages={()=>{setOpen(true)}}
            wordManipulationResponse={wordManipulationDialog}
            setWordManipulationResponse={setWordManipulationDialog}
            filterFunction={filterList}
          />
        </DynamicScrollContent>
      </div>
      <div style={{ flex: "0.75" }} className="ms-1 d-flex flex-column">
        <ImagesContainer imagesFolder={imagesFolder} handleImageClick={handleImageClick} handleImageDblClick={handleImageDoubleClick}/>
        <KeywordContainer imagesSelected={imagesSelected} handleImageClick={handleImageClick} fetchKeywordsStatus={fetchKeywordsStatus} setFetchKeywordsStatus={setFetchKeywordsStatus}/>
      </div>
      <ConfirmDialog 
        open={open} 
        handleSuccess={handleConfirmSuccess} 
        handleClose={handleComfirmClose}
        header="Are you sure you want to delete"
      />
      {
        
        multiAddDialog.open && <ConfirmDialog 
        open={multiAddDialog.open} 
        handleSuccess={()=>{
          setMultiAddDialog({
            open: false,
            confirm: true,
          })
        }} 
        handleClose={()=>{
          setMultiAddDialog({
            open: false,
            confirm: false
          })
        }}
        header="Are you sure you want to add the selected words?"
      />
      }
      {
        multiRemoveDialog.open && <ConfirmDialog 
        open={multiRemoveDialog.open} 
        handleSuccess={()=>{
          setMultiRemoveDialog({
            open: false,
            confirm: true,
          })
        }} 
        handleClose={()=>{
          setMultiRemoveDialog({
            open: false,
            confirm: false
          })
        }}
        header="Are you sure you want to remove the selected words?"
      />
      }
      {
        wordManipulationDialog.open && <ConfirmDialog 
        open={wordManipulationDialog.open} 
        handleSuccess={()=>{
          setWordManipulationDialog({
            ...wordManipulationDialog,
            open: false,
            confirm: true,
          })
        }} 
        handleClose={()=>{
          setWordManipulationDialog({
            ...wordManipulationDialog,
            open: false,
            confirm: false
          })
        }}
        header={wordManipulationDialog.message}
      />
      }
      {
        (viewImage === true)?<ImageDialog
          imageFile={imageToPreview}
          messageHeading={imageToPreview?.image_name}
          screen={'search'}
          cancelClick={() => {
            setViewImage(false);
          }}
        />:''
      }
    </div>
  )
}
