import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getKeywordsCateogyListing = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.keywordsCateogyListing}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const createKeywordApi = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.createKeyword}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};
export const deleteKeywordApi = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.deleteKeyword}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};
export const editKeywordApi = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.editKeyword}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};