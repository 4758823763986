import React, { useEffect, useState } from 'react'
import { getAllKeywordsFolders } from '../../services/SearchScreenApis'
import DynamicScrollContent from '../HOC/DynamicScrollContent'
import Input from "../CommonComp/Input"
function KeywordContainer({imagesSelected=[], fetchKeywordsStatus = false,handleImageClick=()=>{}, setFetchKeywordsStatus=()=>{}}) {
  const [imageKeywords, setImageKeywords] = useState([])
  // const [imageIds, setImageIds] = useState("")
  
  const getImageKeywords = async (imageIds) =>{
    if(imageIds){
      let resp = await getAllKeywordsFolders({image: imageIds})
      if(resp?.length){
        setImageKeywords(resp)
      }
    }
  }

  const selectImages = (checkedStatus) =>{
    if(checkedStatus == true){
      let imageContainer = document.querySelector(".imageContainer");
      let images = imageContainer.querySelectorAll(".item-image");
      let imageNames = [];
      images.forEach((item)=>{
        item.classList.add("selected");
        imageNames.push(item.dataset.imageName)
      });
      handleImageClick(imageNames);
    }
    else{
      let imageContainer = document.querySelector(".imageContainer");
      let images = imageContainer.querySelectorAll(".item-image");
      let imageNames = [];
      images.forEach((item)=>{
        item.classList.remove("selected");
      });
      handleImageClick(imageNames);
    }
  }

  useEffect(()=>{
    if(fetchKeywordsStatus?.status && fetchKeywordsStatus?.from === 'normal'){
      if(imagesSelected?.length){
        getImageKeywords(imagesSelected?.join(','))
      }else{
        setImageKeywords([])
      }
      setFetchKeywordsStatus({
        status: false,
        from: "normal"
      })
    }

    if(fetchKeywordsStatus?.status && fetchKeywordsStatus?.from === 'reset'){
      setImageKeywords([])
      setFetchKeywordsStatus({
        status: false,
        from: "normal"
      })
    }
  },[imagesSelected, fetchKeywordsStatus])
  useEffect(()=>{
    if(imagesSelected?.length == 0){
      setImageKeywords([]);
    }
  },[imagesSelected])
  
  return (
    <div style={{flex:  0.25}} className='d-flex mt-2 flex-column'>
      <div className='font13 d-flex mb-1 align-items-center justify-content-between'>
        <div className='fontWeightBold me-1'>Image Name: {imagesSelected[0]}</div>
        <Input
        type="checkbox"
        label="Select All Images"
        onChange = {(e)=>{selectImages(e.target.checked)}}
      />
      </div>
      <DynamicScrollContent internalContainerProps={{
        style:{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
          padding: 5
        }
      }} outerContainerProps={{
        className: "border-1"
      }}
      outerContainerStyle={{
        flex: 1
      }}
      >
         <div style={{outline: 'none'}} contentEditable className='font13'>{imageKeywords?.join(", ")}</div>
          
      </DynamicScrollContent>
    </div>
  )
}

export default KeywordContainer