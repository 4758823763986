import React from "react"
import DynamicScrollContent from "../HOC/DynamicScrollContent"
import CustomImage from "../CommonComp/CustomImage"
import ApiConfig from "../../config/ApiConfig"
import Selecto from "react-selecto"
import './styles.css'
import useKeyPressStatus from "../../hooks/useKeyPressStatus"
import { findObjectInArray, findObjectIndexInArray } from "../../lib/helper"

function ImagesContainer({ imagesFolder = [],handleImageClick = ()=>{}, handleImageDblClick = () => {}}) {
  const selectoRef = React.useRef(null);
  const [scrollOptions, setScrollOptions] = React.useState({});
  const scrollerRef = React.useRef(null);
  let pressedKey = useKeyPressStatus();
  const [selectedItems, setSelectedItems] = React.useState([])
  const [selectStates, setSelectStates] = React.useState({
    startIndex: null,
    endIndex: null,
    firstIndexItem: null,
    lastIndexItem: null,
    includeItem:[],
  })
  React.useEffect(() => {
    setScrollOptions({
        container: scrollerRef.current,
        throttleTime: 30,
        threshold: 0,
    });
  }, []);
  
  
  React.useEffect(() => {
    // if(selectStates?.startIndex != null){
    //   let finalItems = []
    //   if(selectStates?.endIndex != null){
    //     for(let i = 0; i < selectStates?.includeItem.length; i++){
    //       finalItems.push(imagesFolder[selectStates.includeItem[i]])
    //     }
    //     // for(let i = selectStates.startIndex; i <= selectStates?.endIndex; i++){
    //     //   finalItems.push(imagesFolder[i]);
    //     // }
    //   }else{
    //     finalItems.push(imagesFolder[selectStates?.startIndex]);
    //   }
    //   hImageClick(finalItems)
    // }
    // else{
    //   if(selectStates.includeItem.length !=0){
    //     let finalItems = [];
    //     for(let i=0; i<selectStates.includeItem.length; i++){
    //       finalItems.push(imagesFolder[selectStates.includeItem[i]]);
    //     }
    //     hImageClick(finalItems);
    //   }
    //   else{
    //     hImageClick([]);
    //   }
    // }
    if(selectStates.includeItem.length !=0){
      let finalItems = [];
      for(let i=0; i<selectStates.includeItem.length; i++){
        finalItems.push(imagesFolder[selectStates.includeItem[i]]);
      }
      hImageClick(finalItems);
    }
    else{
      hImageClick([]);
    }
  },[selectStates]);


  const hImageClick = (item)=>{
   let data = item
    const arrayUniqueByKey = [...new Map(data.map(item =>
      [item['image_name'], item])).values()];
    let selectedImagesName = arrayUniqueByKey.map((item)=>{
      return item.image_name;
    });
    handleImageClick(selectedImagesName);
    setSelectedItems(arrayUniqueByKey);
      // console.log(arrayUniqueByKey)
    }
  return (
    <>
      <DynamicScrollContent
        internalContainerProps={{
          style: {
            display: "flex",
            // flex: 1,
            flexWrap: "wrap",
          },
          className: "imageContainer",
        }}
        outerContainerProps={{
          className: "border-1",
          onClick: ()=>{
            if(!pressedKey?.includes('Shift') && !pressedKey?.includes('Meta') && !pressedKey?.includes('Control')){
              setSelectStates({
                startIndex: null,
                endIndex: null,
                firstIndexItem: null,
                lastIndexItem: null,
                includeItem: [],
              })
            }
          }
        }}
        ref={scrollerRef} onScroll={() => {
          selectoRef.current.checkScroll();
        }}
      >
        {[...imagesFolder]?.map((item, index) => {
          return (
            <div
              key={'imageItem' + index}
              onClick={(e) => {
                e.stopPropagation();  
                if(pressedKey?.includes('Shift')){
                  // if(selectStates.includeItem.length > 0){
                  //   let items = [];
                  //   for(let i = selectStates.includeItem[0]; i<=index; i++){
                  //     items.push(i)
                  //   }
                  //   setSelectStates({
                  //     ...selectStates,
                  //     startIndex: selectStates.includeItem[0],
                  //     lastIndexItem: item,
                  //     endIndex: index,
                  //     includeItem: [...items]
                  //   })
                  // }
                  // else if(selectStates?.startIndex == null){
                  //   setSelectStates({
                  //     ...selectStates,
                  //     startIndex: index,
                  //     firstIndexItem: item,
                  //     includeItem: [index],
                  //   })
                  // }
                  // else if((selectStates?.startIndex === 0 && pressedKey?.includes('Shift') && selectStates?.startIndex < index) || (selectStates?.startIndex && pressedKey?.includes('Shift') && selectStates?.startIndex < index)){
                  //   let itemsToinclude = [];
                  //   for(let i=selectStates.startIndex; i<=index; i++){
                  //     itemsToinclude.push(i)
                  //   }
                  //   setSelectStates({
                  //     ...selectStates,
                  //     endIndex: index,
                  //     lastIndexItem: item,
                  //     includeItem: [...itemsToinclude]
                  //   })
                  // }

                  // else if(selectStates?.startIndex > index){
                  //   setSelectStates({
                  //     ...selectStates,
                  //     startIndex: null,
                  //     endIndex: null,
                  //     firstIndexItem: null,
                  //     lastIndexItem: null
                  //   })
                  // }
                  if(selectStates.includeItem.length == 0){
                    setSelectStates({
                      startIndex: index,
                      endIndex: index,
                      firstIndexItem: item,
                      lastIndexItem: item,
                      includeItem: [index]
                    });
                  }else{
                    if(selectStates.endIndex !== null && index > selectStates.endIndex){
                      let includeItemIndexes = [];
                      for(let i=selectStates.startIndex; i<=index; i++){
                        includeItemIndexes.push(i);
                      }
                      setSelectStates({
                        ...selectStates,
                        endIndex: index,
                        lastIndexItem: item,
                        includeItem: [...includeItemIndexes]
                      })
                    }else if(selectStates.startIndex !=null && index <= selectStates.startIndex){
                      let includeItemIndexes = [];
                      for(let i=index; i<=selectStates.startIndex; i++){
                        includeItemIndexes.push(i);
                      }
                      setSelectStates({
                        startIndex: index,
                        endIndex: selectStates.startIndex,
                        firstIndexItem: item,
                        lastIndexItem: selectStates.firstIndexItem,
                        includeItem: [...includeItemIndexes]
                      });
                    }else if((selectStates.startIndex !=null && selectStates.endIndex !=null) && index > selectStates.startIndex && index < selectStates.endIndex){
                      let includeItemIndexes = [];
                      for(let i=selectStates.startIndex; i<=index; i++){
                        includeItemIndexes.push(i);
                      }
                      setSelectStates({
                        ...selectStates,
                        endIndex: index,
                        lastIndexItem: item,
                        includeItem: [...includeItemIndexes]
                      });
                    }else if(selectStates.startIndex == null && selectStates.endIndex == null){
                      let includeItemList = [...selectStates.includeItem];
                      includeItemList.sort((a, b)=> a - b);

                      if(index < (includeItemList[(includeItemList.length - 1)]) && index > includeItemList[0]){
                        let includeItemIndexes = [];
                        for(let i=includeItemList[0]; i<=index; i++){
                          includeItemIndexes.push(i);
                        }
                        setSelectStates({
                          ...selectStates,
                          startIndex: includeItemList[0],
                          endIndex: index,
                          includeItem: [...includeItemIndexes]
                        })
                      }else if(index < includeItemList[0]){
                        let includeItemIndexes = [];
                        for(let i=index; i<=includeItemList[0]; i++){
                          includeItemIndexes.push(i);
                        }
                        setSelectStates({
                          ...selectStates,
                          startIndex: index,
                          endIndex: includeItemList[0],
                          includeItem: [...includeItemIndexes]
                        })
                      }else{
                        let includeItemIndexes = [];
                        for(let i=includeItemList[0]; i<=index; i++){
                          includeItemIndexes.push(i);
                        }
                        setSelectStates({
                          ...selectStates,
                          startIndex: includeItemList[0],
                          endIndex: index,
                          includeItem: [...includeItemIndexes]
                        });
                      }
                    }
                  }
                }
                // else if(pressedKey?.includes('Meta')){
                //   if(selectStates.includeItem.includes(index)){
                //     if(selectStates.startIndex == index){
                //       //changing start index
                      
                //       let newStartIndex;

                //       //get current position of index in include item array.
                //       let currentIndexPosition = selectStates.includeItem.indexOf(index);
                //       //Setting new start index to 1 index greater than current.
                //       let updatedIndex =  selectStates.includeItem[currentIndexPosition+1]; 
                //       //If include item array has the new index included then set it as new start index, else set it to null.
                //       if(selectStates.includeItem.includes(updatedIndex)){
                //         newStartIndex = updatedIndex;
                //       }
                //       else{
                //         newStartIndex = null;
                //       }
                //       let includedItemsList = selectStates.includeItem;
                //       let itemToRemoveIndex = includedItemsList.indexOf(index);
                //       if(itemToRemoveIndex > -1){
                //         includedItemsList.splice(itemToRemoveIndex, 1);
                //       }
                //       setSelectStates({
                //         ...selectStates,
                //         startIndex: newStartIndex,
                //         includeItem: [...includedItemsList]
                //       })
                //     }
                //     else if(selectStates.endIndex == index){
                //       //changing end index
                      
                //       let newEndIndex;

                //       //get current position of index in include item array.
                //       let currentIndexPosition = selectStates.includeItem.indexOf(index);
                //       //Setting new start index to 1 index greater than current.
                //       let updatedIndex =  selectStates.includeItem[currentIndexPosition-1]; 
                //       //If include item array has the new index included then set it as new start index, else set it to null.
                //       if(selectStates.includeItem.includes(updatedIndex)){
                //         newEndIndex = updatedIndex;
                //       }
                //       else{
                //         newEndIndex = null;
                //       }

                //       let includedItemsList = selectStates.includeItem;
                //       let itemToRemoveIndex = includedItemsList.indexOf(index);
                //       if(itemToRemoveIndex > -1){
                //         includedItemsList.splice(itemToRemoveIndex, 1);
                //       }
                //       setSelectStates({
                //         ...selectStates,
                //         startIndex: newEndIndex,
                //         includeItem: [...includedItemsList]
                //       })
                //     }
                //     else{
                //       let includedItemsList = selectStates.includeItem;
                //       let itemToRemoveIndex = includedItemsList.indexOf(index);
                //       if(itemToRemoveIndex > -1){
                //         includedItemsList.splice(itemToRemoveIndex, 1);
                //       }
                //       setSelectStates({
                //         ...selectStates,
                //         includeItem: [...includedItemsList]
                //       })
                //     }

                    
                //   }
                //   else{
                //     setSelectStates({
                //       ...selectStates,
                //       includeItem: [...selectStates.includeItem, index]
                //     })
                //   }
                // }
                else if(pressedKey?.includes('Control') || pressedKey?.includes('Meta')){
                  if(selectStates.includeItem.includes(index)){
                    // if(selectStates.startIndex == index){
                    //   //changing start index
                      
                    //   let newStartIndex;

                    //   //get current position of index in include item array.
                    //   let currentIndexPosition = selectStates.includeItem.indexOf(index);
                    //   //Setting new start index to 1 index greater than current.
                    //   let updatedIndex =  selectStates.includeItem[currentIndexPosition+1]; 
                    //   //If include item array has the new index included then set it as new start index, else set it to null.
                    //   if(selectStates.includeItem.includes(updatedIndex)){
                    //     newStartIndex = updatedIndex;
                    //   }
                    //   else{
                    //     newStartIndex = null;
                    //   }
                    //   let includedItemsList = selectStates.includeItem;
                    //   let itemToRemoveIndex = includedItemsList.indexOf(index);
                    //   if(itemToRemoveIndex > -1){
                    //     includedItemsList.splice(itemToRemoveIndex, 1);
                    //   }
                    //   setSelectStates({
                    //     ...selectStates,
                    //     startIndex: newStartIndex,
                    //     includeItem: [...includedItemsList]
                    //   })
                    // }
                    // else if(selectStates.endIndex == index){
                    //   //changing end index
                      
                    //   let newEndIndex;

                    //   //get current position of index in include item array.
                    //   let currentIndexPosition = selectStates.includeItem.indexOf(index);
                    //   //Setting new start index to 1 index greater than current.
                    //   let updatedIndex =  selectStates.includeItem[currentIndexPosition-1]; 
                    //   //If include item array has the new index included then set it as new start index, else set it to null.
                    //   if(selectStates.includeItem.includes(updatedIndex)){
                    //     newEndIndex = updatedIndex;
                    //   }
                    //   else{
                    //     newEndIndex = null;
                    //   }

                    //   let includedItemsList = selectStates.includeItem;
                    //   let itemToRemoveIndex = includedItemsList.indexOf(index);
                    //   if(itemToRemoveIndex > -1){
                    //     includedItemsList.splice(itemToRemoveIndex, 1);
                    //   }
                    //   setSelectStates({
                    //     ...selectStates,
                    //     startIndex: newEndIndex,
                    //     includeItem: [...includedItemsList]
                    //   })
                    // }
                    // else{
                    //   let includedItemsList = selectStates.includeItem;
                    //   let itemToRemoveIndex = includedItemsList.indexOf(index);
                    //   if(itemToRemoveIndex > -1){
                    //     includedItemsList.splice(itemToRemoveIndex, 1);
                    //   }
                    //   setSelectStates({
                    //     ...selectStates,
                    //     startIndex: null,
                    //     endIndex: null,
                    //     includeItem: [...includedItemsList]
                    //   })
                    // }
                    let includedItemsList = selectStates.includeItem;
                    let itemToRemoveIndex = includedItemsList.indexOf(index);
                    includedItemsList.splice(itemToRemoveIndex, 1);
                    setSelectStates({
                      startIndex: null,
                      endIndex: null,
                      firstIndexItem: null,
                      lastIndexItem: null,
                      includeItem: [...includedItemsList]
                    })
                  }
                  else{
                    setSelectStates({
                      startIndex: null,
                      endIndex: null,
                      firstIndexItem: null,
                      lastIndexItem: null,
                      includeItem: [...selectStates.includeItem, index]
                    })
                  }
                }
                else{
                  setSelectStates({
                    startIndex: null,
                    endIndex: null,
                    firstIndexItem: null,
                    lastIndexItem: null,
                    includeItem:[index],
                  })
                }

              }}
              onDoubleClick={()=>{handleImageDblClick(item)}}
              className={`item-image m-3 ${findObjectIndexInArray(selectedItems, 'image_name', item?.image_name) > -1 ? 'selected': ""}`}
              data-image-name={item?.image_name}
            >
              <CustomImage
                src={`${ApiConfig?.S3_BASE_URL}keywordingImages/${item?.shoot_id}/${item?.image_name}.jpg`}
                className="img-fluid"
                style={{ maxHeight: 100 }}
              />
              <div
                style={{ fontSize: 12, fontWeight: "bold", marginTop: 2 }}
                className="single-line"
              >
                {item?.image_name}
              </div>
            </div>
          )
        })}
      <Selecto
        ref={selectoRef}
        // The container to add a selection element
        container={document.querySelector(".imageContainer")}
        // The area to drag selection element (default: container)
        dragContainer={document.querySelector(".imageContainer")}
        // Targets to select. You can register a queryselector or an Element.
        selectableTargets={[".item-image"]}
        // Whether to select by click (default: true)
        selectByClick={false}
        // Whether to select from the target inside (default: true)
        selectFromInside={false}
        // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
        continueSelect={false}
        // Determines which key to continue selecting the next target via keydown and keyup.
        toggleContinueSelect={"shift"}
        // The container for keydown and keyup events
        keyContainer={window}
        scrollOptions={scrollOptions}
        // The rate at which the target overlaps the drag area to be selected. (default: 100)
        hitRate={0}
        ratio={0}
        // onSelect={(e) => {
        //   e.added.forEach((el) => {
        //     el.classList.add("selected")
        //   })
        //   e.removed.forEach((el) => {
        //     el.classList.remove("selected")
        //   })
        // }}
        onScroll={e => {
          scrollerRef.current.scrollBy(e.direction[0] * 10, e.direction[1] * 10);
        }}
        onSelectEnd={(e)=>{
          const selected = e.selected.map((element) => findObjectInArray(imagesFolder, 'image_name', element.dataset?.imageName) );
          const selectedItemIndexes = e.selected.map((element) => findObjectIndexInArray(imagesFolder, 'image_name', element.dataset?.imageName) );
          // hImageClick(selected)
          if((pressedKey?.includes('Control') || pressedKey?.includes('Meta')) && selectStates.includeItem.length !=0){
            let includeItemListCopy = [...selectStates.includeItem];
            selectedItemIndexes.forEach((itemIndex)=>{
              if(includeItemListCopy.includes(itemIndex)){
                let index = includeItemListCopy.indexOf(itemIndex);
                includeItemListCopy.splice(index,1);
              }else{
                includeItemListCopy.push(itemIndex);
              }
            });
            setSelectStates({
              ...selectStates,
              startIndex:null,
              endIndex: null,
              includeItem: [...includeItemListCopy]
            });
          }else{
            if(selectedItemIndexes?.length !=0){
              setSelectStates({
                ...selectStates,
                startIndex: null,
                endIndex: null,
                includeItem: [...selectedItemIndexes]
              })
            }
          }
        }}
        preventClickEventOnDrag ={true}
      />
      </DynamicScrollContent>
      {/* <button onClick={()=>{
        const imageContanier = document.querySelector('.imageContainer')
        const selecto = selectoRef.current
        console.log(selecto ,imageContanier.scrollHeight, imageContanier.scrollWidth)
        selecto.selectionElement.style.height = 100
        selecto.selectionElement.style.width = 100
        selecto.selectionElement.style.display = 'block'
      }}>select all</button> */}
      
    </>
  )
}

export default ImagesContainer
