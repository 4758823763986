import React from "react"
// import Select from "react-select"
import Select from "react-select-virtualized"
import './customSelect.css'

const CustomSelect = React.forwardRef(({ options = [], ...props },ref)=>{
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: "30px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "0px",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
      fontSize: "12px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      fontSize: "12px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: 0
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1,
      minWidth: 185,
      maxHeight: 100,
      borderRadius: 0,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      padding: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--color-3)"
        : state.isFocused
        ? "var(--color-1)"
        : state.data.color,
      fontSize: "12px",
      cursor: "pointer",
      padding: '5px 12px'
    }),
  }

  return (
    <Select
      options={options}
      styles={customStyles}
      isSearchable={false}
      isClearable={false}
      menuPlacement={"auto"}
      ref={ref}
      {...props}
    />
  )
});

export default CustomSelect
