// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font12 {
    font-size: 12px;
}
.font13 {
    font-size: 13px;
}
.fontWeightBold {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Asset/css/fontSize.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".font12 {\n    font-size: 12px;\n}\n.font13 {\n    font-size: 13px;\n}\n.fontWeightBold {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
