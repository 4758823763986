import React, { useEffect, useRef, useState } from 'react';
import Input from '../CommonComp/Input';
import { onlyUnique } from '../../lib/helper';
import DynamicScrollContent from '../HOC/DynamicScrollContent';
import ImageDialog from '../CommonComp/modals/ImageDialog';
import imageIcon from '../../Asset/icons/imageIcon.png';
import detailViewIcon from '../../Asset/icons/detailViewIcon.png';
import iconViewIcon from '../../Asset/icons/iconViewIcon.png';
import tileViewIcon from '../../Asset/icons/tileViewIcon.png';
import { createPortal } from 'react-dom';
import WindowCreater from '../HOC/WindowCreater';
import { useAppContext } from '../../context/AppContext';
import addfileIcon from '../../Asset/icons/addFileIcon.png';
import allImagesIcon from '../../Asset/icons/allImagesIcon.png';
function RightSection({
  actualKeywords,
  handleFileChange,
  handleSelectedFile,
  selectedFile,
  selectedWords = '',
  setSelectedWords = () => {},
  blobURL,
  setActualKeywords = () => {},
  clearImageField = () => {},
  setPresentCounter = () => {},
  allFiles = [],
  captionData = {
    enable: false,
    data: '',
  },
  setCaptionData = () => {},
  isMaximized,
  parentDimensions,
  removeImageFromList = () => {},
}) {
  const {bringToFront} = useAppContext();
  let imageContainer = document.getElementById('imagePreviewWindowContainer');
  const [selectedView, setSelectedView] = useState('');
  const [viewImage, setViewImage] = useState(false);
  useEffect(() => {
    let newString = actualKeywords?.length ? actualKeywords?.join(',') : '';
    let joinedString = selectedWords.concat(',', newString);
    let wordArray = joinedString?.split(/,\s*|,/);
    var unique = onlyUnique(wordArray)?.join(', ');
    setSelectedWords(unique);
  }, [actualKeywords]);

  const [selectedFolder, setSelectedFolder] = useState('');
  useEffect(() => {
    if (allFiles?.length && allFiles?.length != 0) {
      let folderNameString = allFiles[0].webkitRelativePath;
      let splittedResult = folderNameString.split('/');
      setSelectedFolder(splittedResult[0]);
    } else {
      setSelectedFolder('');
    }
  }, [allFiles]);
  const [minimized, setIsMinimized] = useState('');
  const closeImagesWindow = () => {
    imageContainer.style.display = 'none';
    setIsMinimized(true);
  };
  const openImagesPanel = () => {
    let window = imageContainer.querySelector(
      '#draggable-window-imageContainer1'
    );
    window.style.display = '';
    imageContainer.style.display = '';
    setIsMinimized(false);
  };
  return (
    <>
      <div className="d-flex flex-column">
        <h6
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Selected Actual Words
          <Input
            type="checkbox"
            label="Caption"
            disableSwitch={false}
            checked={captionData?.enable}
            onChange={(e) => {
              setCaptionData({
                enable: e?.target?.checked,
                data: '',
              });
            }}
          />
        </h6>
        <Input
          value={selectedWords}
          placeholder="Selected Words"
          style={{
            height: 90,
            maxHeight: 120,
            width: '100%',
          }}
          type="textarea"
          onChange={(e) => {
            setSelectedWords(e?.target?.value);
          }}
          onBlur={(e) => {
            let removedSpecailChar = selectedWords.replace(/[^\w-\s,]/g, '').replace(/,{2,}/g, ',').replace(/^,|,$/g, '');
            let wordArray = removedSpecailChar?.split(/,\s*|,/);
            var unique = onlyUnique(wordArray)?.join(', ');
            setSelectedWords(unique);
            setActualKeywords(onlyUnique(wordArray));
            if (!e.target?.value) {
              setActualKeywords([]);
            }
          }}
        />
        {captionData?.enable ? (
          <Input
            type="textarea"
            value={captionData?.data}
            placeholder="Caption"
            onChange={(e) => {
              setCaptionData({
                ...captionData,
                data: e?.target?.value,
              });
            }}
            mainContainerProps={{
              className: 'mt-2 mb-1',
            }}
          />
        ) : null}
      </div>
      <div style={{ marginTop: 20 }}>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className='d-flex align-items-center'>
            <label htmlFor='formFileSm' style={{cursor: 'pointer'}} title='Choose File'>
              <input
                class="form-control form-control-sm"
                id="formFileSm"
                type="file"
                onChange={handleFileChange}
                name="formFileSm"
                webkitdirectory=""
                directory=""
                style={{display:'none'}}
              />
              <img style={{width:35}} src={addfileIcon} alt='Select Image folder'/>
            </label>
            <p className="font12 mb-0 ms-3">
              Folder No.: {selectedFolder != '' ? selectedFolder : 'N.A'}{' '}
            </p>
            <p className="font12 mb-0 ms-2">
              Image: {selectedFile ? selectedFile.name : 'N.A'}
            </p>
          </div>
          
          {minimized == true && (
            
            <button
            onClick={openImagesPanel}
            className="bg-white border-0"
            title='Open Images Panel'
          >
            <img src={allImagesIcon} alt='Open Images Panel' style={{width:35, cursor: 'pointer'}}/>
          </button>
          )}
        </div>
        
      </div>
      

      {allFiles &&
        !selectedFile &&
        createPortal(
          <WindowCreater
            title={<div className='user-select-none-all-child'><div>Select An Image</div></div>}
            x={10}
            y={50}
            resizable={false}
            id={'imageContainer1'}
            onBringToFront={bringToFront}
            handleCloseWindow={() => {
              closeImagesWindow();
            }}
            loading={false}
            style={{ zIndex: 2 }}
          >
            <DynamicScrollContent
              outerContainerStyle={{ flex: 1 }}
              outerContainerProps={{
                className: 'mt-0 image-container',
              }}
              internalContainerProps={{
                style: {
                  background: !allFiles?.length ? 'aliceblue' : 'white',
                  // display: 'flex',
                  // flexWrap: 'wrap',
                  position: 'static',
                  maxHeight: 550,
                },
              }}
            >
              <div style={{zIndex:2}} className="mt-0 mb-2 user-select-none-all-child text-right position-sticky top-0 w-100 bg-white">
                <div className='d-flex'>
                <div
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center me-3"
                  onClick={() => {
                    setSelectedView('Tiles');
                  }}
                >
                  <img src={tileViewIcon} style={{ width: 20 }} />
                  <p className="ms-2 mb-0">Tiles</p>
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center me-3"
                  onClick={() => {
                    setSelectedView('Icons');
                  }}
                >
                  <img src={iconViewIcon} style={{ width: 20 }} />
                  <p className="ms-2  mb-0">Icons</p>
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center me-3"
                  onClick={() => {
                    setSelectedView('Image ID');
                  }}
                >
                  <img src={detailViewIcon} style={{ width: 20 }} />
                  <p className="ms-2  mb-0">Image Id</p>
                </div>
                  </div>
                  <hr />
              </div>
              
              <div className="d-flex flex-wrap mt-2">
                {!selectedFile &&
                  allFiles?.map((file, index) => {
                    const fileBlobURL = URL?.createObjectURL(file);
                    if (selectedView === 'Tiles') {
                      return (
                        <div
                          style={{
                            position: 'relative',
                            width:192,
                            maxWidth: 192,
                            maxHeight: 60,

                            // height: 50,
                            margin: 3,
                            cursor: 'pointer',
                            border: '2px solid var(--color-5)',
                            padding: 2,
                          }}
                          onDoubleClick={(e)=>{
                            e.stopPropagation();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setTimeout(()=>{
                              handleSelectedFile(file);
                              bringToFront(3);
                            },300)
                          }}
                          className="my-2 user-select-none-all-child"
                        >
                          <button
                            style={{
                              right: -5,
                              top: -7,
                              padding: '2px 5px',
                              background: 'red',
                              position: 'absolute',
                              color: 'white',
                              fontWeight: 'bold',
                              borderRadius: 0,
                              fontSize: '10px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeImageFromList(file?.name);
                            }}
                            className="btn btn-sm"
                          >
                            X
                          </button>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                overflow: 'hidden',
                                maxHeight: 50,
                              }}
                              className="w-25"
                            >
                              <img
                                src={fileBlobURL}
                                alt={file?.name || 'Preview'}
                                style={{
                                  ...(!selectedFile
                                    ? { objectFit: 'cover' }
                                    : {}),
                                  objectPosition: 'center center',
                                  height: '50px',
                                  width: 'auto',
                                }}
                              />
                            </div>
                            <div className="w-75">
                              <p
                                style={{ fontSize: 12, fontWeight: 'bold' }}
                                className="single-line ms-2 mb-1 d-block"
                              >
                                {file?.name}
                              </p>
                              <p
                                style={{ fontSize: 12, fontWeight: 'bold' }}
                                className="single-line ms-2 mb-0 d-block"
                              >
                                Type: {file.type}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    } else if (selectedView === 'Image ID') {
                      return (
                        <div
                          style={{
                            position: 'relative',
                            maxWidth: 190,
                            width:190,
                            maxHeight: 120,
                            margin: 5,
                            cursor: 'pointer',
                            border: '2px solid var(--color-5)',
                            padding: 2,
                          }}
                          onDoubleClick={(e)=>{
                            e.stopPropagation();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setTimeout(()=>{
                              handleSelectedFile(file);
                              bringToFront(3);
                            },300)
                          }}
                          className="my-2 user-select-none-all-child"
                        >
                          <button
                            style={{
                              right: -5,
                              top: -10,
                              padding: '2px 5px',
                              background: 'red',
                              position: 'absolute',
                              color: 'white',
                              fontWeight: 'bold',
                              borderRadius: 0,
                              fontSize: '10px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeImageFromList(file?.name);
                            }}
                            className="btn btn-sm"
                          >
                            X
                          </button>
                          <div className="d-flex align-items-center">
                            <img
                              src={imageIcon}
                              // alt={file?.name || 'Preview'}
                              // style={{
                              //   ...(!selectedFile ? { objectFit: 'cover' } : {}),
                              //   objectPosition: 'center center',
                              //   height: 'auto',
                              //   width: '50px',
                              // }}
                            />
                            <div
                              style={{ fontSize: 12, fontWeight: 'bold' }}
                              className="single-line"
                            >
                              {file?.name}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            position: 'relative',
                            maxWidth: 100,
                            maxHeight: 120,
                            margin: 5,
                            cursor: 'pointer',
                            border: '2px solid var(--color-5)',
                            padding: 2,
                          }}
                          onDoubleClick={(e)=>{
                            e.stopPropagation();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setTimeout(()=>{
                              handleSelectedFile(file);
                              bringToFront(3);
                            },300)
                          }}
                          className='user-select-none-all-child'
                        >
                          <button
                            style={{
                              right: 0,
                              top: 0,
                              padding: '2px 5px',
                              background: 'red',
                              position: 'absolute',
                              color: 'white',
                              fontWeight: 'bold',
                              borderRadius: 0,
                              fontSize: '12px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeImageFromList(file?.name);
                            }}
                            className="btn btn-sm"
                          >
                            X
                          </button>
                          <img
                            src={fileBlobURL}
                            alt={file?.name || 'Preview'}
                            style={{
                              ...(!selectedFile ? { objectFit: 'cover' } : {}),
                              objectPosition: 'center center',
                              height: '96px',
                              width: '92px',
                            }}
                          />
                          <div
                            style={{ fontSize: 12, fontWeight: 'bold' }}
                            className="single-line"
                          >
                            {file?.name}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </DynamicScrollContent>
          </WindowCreater>,
          imageContainer
        )}
      {selectedFile && (
        <div
          className="mt-0 image-container"
          style={{
            background: selectedFile ? 'aliceblue' : 'white',
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
          }}
        >
          {selectedFile && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                backgroundImage: `url(${blobURL})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }}
              onDoubleClick={() => {
                setViewImage(true);
              }}
            >
              <button
                style={{
                  right: 4,
                  top: 4,
                  padding: '2px 10px',
                  background: 'red',
                  position: 'absolute',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  clearImageField();
                  setPresentCounter(0);
                }}
                className="btn btn-sm"
              >
                X
              </button>
            </div>
          )}
        </div>
      )}
      {viewImage ? (
        <ImageDialog
          imageFile={selectedFile}
          messageHeading={selectedFile?.name}
          cancelClick={() => {
            setViewImage(false);
          }}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default RightSection;
