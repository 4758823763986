import { createContext, useContext, useMemo, useState } from "react";
import { findObjectInArray, findObjectIndexInArray } from '../lib/helper';
export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [menu, setMenu] = useState([
        {
            title: 'Data Export',
            url: '/',
            visible: false,
            id: 1,
            x: 35,
            y: 0,
            pWidth: 600,
            pHeight: 200,
            style: {zIndex: 1}
        },
        {
          title: 'Relationship',
          url: '/',
          visible: false,
          id: 2,
          x: 10,
          y: 0,
          pWidth: 1200,
          pHeight: 720,
          style: {zIndex: 1},
          // submenu: [
          //   {
          //     title: 'Web Design',
          //     url: '',
          //     visible: false,
          //     id: 21,
          //     x: 20,
          //     y: 20,
          //     style: {zIndex: 1}
          //   },
          //   {
          //     title: 'Web Development',
          //     url: '',
          //     visible: false,
          //     id: 22,
          //     x: 30,
          //     y: 30,
          //     style: {zIndex: 1}
          //   },
          //   {
          //     title: 'SEO',
          //     url: '',
          //     visible: false,
          //     id: 23,
          //     x: 35,
          //     y: 35,
          //     style: {zIndex: 1}
          //   },
          // ],
        },
        {
            title: 'Image Keywording',
            url: '/',
            visible: false,
            id: 3,
            x: 0,
            y: 0,
            pWidth: 1350,
            pHeight: 720,
            style: {zIndex: 1}
        },
        {
            title: 'Search',
            url: '/',
            visible: false,
            id: 4,
            x: 25,
            y: 0,
            pWidth: 1200,
            pHeight: 700,
            style: {zIndex: 1}
        },
        {
            title: 'Enter New Keyword',
            url: '/',
            visible: false,
            id: 5,
            x: 35,
            y: 0,
            pWidth: 1200,
            pHeight: 600,
            style: {zIndex: 1}
        },
        {
          title: 'Rules',
          url: '/',
          visible: false,
          id: 6,
          x: 45,
          y: 0,
          pWidth: 1200,
          pHeight: 600,
          style: {zIndex: 1}
      },
      //Added the image container to the menu to use bring to front functionality.
      {
          id: 'imageContainer1',
          style: {zIndex: 2}
      },
    ]);

  const handleMenu = (id, value)=>{
    let arr = [...menu]
    let objIndex = findObjectIndexInArray(arr, 'id', id)
    if(objIndex > -1){
        arr[objIndex].visible = value || false
        arr = arr.map((a, i)=>{
          let obj = {...a}
          if(i === objIndex && value){
            obj.style.zIndex = 2
          }else{
            obj.style.zIndex = 1
          }
          return obj
        });
        arr[objIndex].visible = value || false
        setMenu([...arr])
    }
  }

  const bringToFront = (id) => {
    let arr = [...menu]
    let objIndex = findObjectIndexInArray(arr, 'id', id)
    if(objIndex > -1){
      arr = arr.map((a, i)=>{
        let obj = {...a}
        if(i === objIndex){
          obj.style.zIndex = 2
        }else{
          obj.style.zIndex = 1
        }
        return obj
      });
      setMenu([...arr])
    }
  };

  const value = useMemo(
    () => ({
        menu,
        setMenu,
        handleMenu, 
        bringToFront
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menu]
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext);
};