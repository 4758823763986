import React, { useEffect } from "react"
import Select from "../CommonComp/Select"
import { useAppContext } from "../../context/AppContext"

function SearchSection({
  setActualKeywords = () => {},
  categoriesData = [],
  resetData = false,
  setResetData = () => {},
  selectedCategory = null,
  handleCategoryChange = () => {},
  saveChanges = () => {},
  isMaximized,
  parentDimensions,
}) {
  const { handleMenu } = useAppContext()
  useEffect(() => {
    if (resetData) {
      setActualKeywords([])
      setResetData(false)
    }
  }, [resetData])

  return (
    <div className="row">
      <div className="col-sm">
        <Select
          label={"Select Classification"}
          options={categoriesData}
          labelProps={{
            style: {
              width: 125,
            },
          }}
          value={selectedCategory}
          onChange={handleCategoryChange}
          mainContainerProps={{
            className: "mb-3",
          }}
        />
      </div>
      <div className="col-3">
        <div className=" d-flex justify-content-end align-item-center">
          <button
            type="submit"
            className="btn btn-sm btn-secondary me-2"
            onClick={saveChanges}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary "
            onClick={() => {
              handleMenu(2, false)
            }}
          >
            Exit
          </button>
        </div>
      </div>
    </div>
  )
}

export default SearchSection
