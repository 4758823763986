import React, { useEffect } from 'react'
import Select from '../CommonComp/Select'
import { getCategories, getCategorykeywordlist } from '../../services/keywordsApis'

function RelateWithActualKeyword({categoriesData="", selectedCategory="", selectedCategoryData="", selectedCategoryChild="", 
    setCategoriesData=()=>{}, setSelectedCategory=()=>{}, setSelectedCategoryData=()=>{}, setSelectedCategoryChild=()=>{},
    editMode=false
}) {

    async function fetchCategory() {
        let data = await getCategories()
        if(data?.length){
          let newData = data?.filter((el)=>el?.category_id !== 3)?.map((el)=>{
            return {
              label: el?.category_name,
              value: el?.category_id,
              item: el
            }
          })
          setCategoriesData(newData);
        }
    }

    useEffect(()=>{
        fetchCategory()
    }, [])

    async function fetchSelectedCategoryCategory(id) {
        if(id){
          let data = await getCategorykeywordlist({
            category_id: id,
          })
          return data
        }
      }

    const handleCategoryChange = async(data)=>{
        setSelectedCategory(data)
        setSelectedCategoryChild(null)
        let dataNew = await fetchSelectedCategoryCategory(data?.value)
        if(dataNew?.length){
            let newData = dataNew?.map((el)=>{
                return {
                    label: el?.parent_name,
                    value: el?.parent_name,
                    item: el
                }
            })
            setSelectedCategoryData(newData)
        }
    }
    const handleCategoryChildChange = async(data)=>{
        setSelectedCategoryChild(data)
    }
  return (
    <div className='border-1 mt-2'> 
        <div className="font12 fontWeightBold m-2" style={{ flex: 1 }}>Relate With Actual Keyword</div>
        <div className="m-2 my-1 d-flex flex-column">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Category</div>
            <div>
                <Select 
                    options={categoriesData} 
                    mainContainerProps={{
                        className: 'd-flex m-0 ms-1'
                    }}
                    isDisabled={editMode}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    isSearchable={true}
                />
            </div>
        </div>
        <div className="m-2 my-1 d-flex flex-column mt-3">
            <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Actual Keywords</div>
            <div>
                <Select 
                    options={selectedCategoryData} 
                    mainContainerProps={{
                        className: 'd-flex m-0 ms-1'
                    }}
                    isDisabled={editMode}
                    value={selectedCategoryChild}
                    onChange={handleCategoryChildChange}
                    isSearchable={true}
                />
            </div>
        </div>
    </div>
  )
}

export default RelateWithActualKeyword