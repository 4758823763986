import React, { useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ApiConfig from '../../../config/ApiConfig';
function ImageDialog({
  messageHeading = 'Image',
  imageFile = '',
  okClick = null,
  cancelClick = null,
  screen = null,
}) {
  let fileBlobURL;
  if(screen === 'search'){
    fileBlobURL = `${ApiConfig?.S3_BASE_URL}keywordingImages/${imageFile?.shoot_id}/${imageFile?.image_name}.jpg`;
  }else{
    if (imageFile) {
      fileBlobURL = URL?.createObjectURL(imageFile);
    }
  }
  
  const [scale, setScale] = useState(1);
  const transformComponentRef = useRef(null);
  const updateScale = (e) => {
    const targetScale = parseFloat(e.target.value);
    const factor = Math.log(targetScale / scale);
    const { zoomIn, zoomOut } = transformComponentRef.current;

    if (targetScale > scale) {
      zoomIn(factor, 0);
    } else {
      zoomOut(-factor, 0);
    }

    setScale(targetScale);
  };
  return (
    <div
      style={{
        background: '#01010154',
        position: 'absolute',
        display: 'flex',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: 700 }}>
        <div
          style={{
            background: '#fff',
            maxWidth: 'fit-content',
          }}
        >
          <div
            style={{
              background: 'var(--color-4)',
              padding: 10,
              color: 'black',
              fontWeight: 'bold',
            }}
            className="d-flex justify-content-between align-items-center"
          >
            {messageHeading}
            {/* <span>X</span> */}
          </div>
          <div style={{ background: '#fff', color: 'black', padding: 10 }}>
            {/* {message} */}
            <TransformWrapper
              ref={transformComponentRef}
              initialScale={scale}
              minScale={0.1}
              maxScale={2}
            >
              {({ zoomIn, zoomOut}) => (
                <>
                  <TransformComponent>
                    <img
                      src={fileBlobURL}
                      alt={imageFile?.name || 'Preview'}
                      style={{
                        ...(!imageFile ? { objectFit: 'cover' } : {}),
                        objectPosition: 'center center',
                        maxWidth: '700px',
                        maxHeight: '400px',
                      }}
                    />
                  </TransformComponent>
                  <div className="tools text-center mt-2">
                    <input
                      type="range"
                      min={0.1}
                      max={2}
                      step={0.01}
                      onChange={updateScale}
                    />
                  </div>
                </>
              )}
            </TransformWrapper>
          </div>
          <div
            style={{ background: '#fff' }}
            className="d-flex justify-content-end align-items-center pt-3 ps-3 pe-2 pb-2"
          >
            {/* {okClick && (
              <button
                type="button"
                className="btn btn-sm btn-secondary ms-3"
                onClick={() => {
                  okClick()
                }}
              >
                Okay
              </button>
            )} */}

            {cancelClick && (
              <button
                type="button"
                className="btn btn-sm btn-secondary ms-3"
                onClick={() => {
                  cancelClick();
                }}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageDialog;
