import React, { useState, useEffect } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import DynamicScrollContent from "../components/HOC/DynamicScrollContent"
import { createKeywordApi, deleteKeywordApi, editKeywordApi, getKeywordsCateogyListing } from "../services/EnterNewKeyword"
import SearchSection from "../components/EnterNewKeywordScreen/SearchSection"
import { getCategories } from "../services/keywordsApis"
import SearchTableResult from "../components/EnterNewKeywordScreen/SearchTableResult"
import ClassificationSection from "../components/EnterNewKeywordScreen/ClassificationSection"
import RelateWithActualKeyword from "../components/EnterNewKeywordScreen/RelateWithActualKeyword"
import Input from "../components/CommonComp/Input"
import { computeLoaderState, findObjectInArray } from "../lib/helper"
import ConfirmDialog from "../components/CommonComp/modals/ConfirmDialog";
import MessageDialog from "../components/CommonComp/modals/MessageDialog"
function EnterNewKeyword({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  
  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(5, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {<ChildComponent handleMenu={handleMenu} loading={loading} setLoading={setLoading} />}
    </WindowCreater>
  )
}

export default EnterNewKeyword

const ChildComponent = ({isMaximized, handleMenu=()=>{}, loading, setLoading}) => {
  const [query, setQuery] = useState("")
  const [categoriesData, setCategoriesData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [tableData, setTableData] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [selectedClassification, setSelectedClassification] = useState(null)
  const [enteredKeyword, setEnteredKeyword] = useState('')
  const [hiddenKeyword, setHiddenKeyword] = useState('')
  const [actualCategoriesData, setActualCategoriesData] = useState([])
  const [actualSelectedCategory, setActualSelectedCategory] = useState(null)
  const [selectedCategoryData, setSelectedCategoryData] = useState([])
  const [selectedCategoryChild, setSelectedCategoryChild] = useState(null)
  const [selectedRow, setSelectedRow] = useState([])
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState({
    messageHeading: '',
    open: false,
    message: ''
  })
  async function fetchCategory() {
    setLoading({
      ...loading,
      submit: true
    })
    let data = await getCategories()
    if(data?.length){
      let newData = data?.map((el)=>{
        return {
          label: el?.category_name,
          value: el?.category_id,
          item: el
        }
      })
      setCategoriesData(newData);
    }
    setLoading({
      ...loading,
      submit: false
    })
  }
  async function handleCreateApi(payload) {
    if (
      payload?.classification &&
      payload?.visible_keyword &&
      payload?.hidden_keyword
      ) {
        setLoading({
          ...loading,
          submit: true
        })
        let data = await createKeywordApi(payload)
        if(data){
          fetchSelectedCategory({
            category_id: selectedCategory?.value,
            search: query || ""
          })
          setEditMode(false)
          setSelectedClassification(null)
          setEnteredKeyword('')
          setHiddenKeyword('')
          setActualSelectedCategory(null)
          setSelectedCategoryChild(null)
          setAddModalOpen(false);
        }
        setLoading({
          ...loading,
          submit: false
        })
        setAddModalOpen(false);
    }
    else{
      setAddModalOpen(false);
      setMessageModal({
        ...messageModal,
        open: true,
        message: "Either classification, keyword or hidden keyword is missing. Please check!"
      })
    }
  }

  async function fetchSelectedCategory(payload) {
    setLoading({
      ...loading,
      submit: true
    })
    let data = await getKeywordsCateogyListing(payload)
    setTableData(data)
    setLoading({
      ...loading,
      submit: false
    })
  }
  async function handleDeleteKeyword(payload) {
    if(payload?.keyword_id){
      setLoading({
        ...loading,
        submit: true
      })
      let data = await deleteKeywordApi(payload)
      if(data && selectedCategory){
        fetchSelectedCategory({
          category_id: selectedCategory?.value,
          search: query || ""
        })
        setEditMode(false)
        setSelectedClassification(null)
        setEnteredKeyword('')
        setHiddenKeyword('')
        setActualSelectedCategory(null)
        setSelectedCategoryChild(null)
        setDeleteModalOpen(false)
      }else{
        setLoading({
          ...loading,
          submit: false
        })
        setDeleteModalOpen(false)
      }
    }
  }
  async function handleEditKeyword(payload) {
    if(payload?.keyword_id){
      setLoading({
        ...loading,
        submit: true
      })
      let data = await editKeywordApi(payload)
      if(data && selectedCategory){
        fetchSelectedCategory({
          category_id: selectedCategory?.value,
          search: query || ""
        })
        setEditMode(false)
        setSelectedClassification(null)
        setEnteredKeyword('')
        setHiddenKeyword('')
        setActualSelectedCategory(null)
        setSelectedCategoryChild(null)
        setEditModalOpen(false);
      }else{
        setLoading({
          ...loading,
          submit: false
        })
        setEditModalOpen(false);
      }
    }
  }

  useEffect(() => {
    fetchCategory()
  }, [])

  const handleCategoryChange = (data)=>{
    setSelectedCategory(data)
  }
  const handleTableItemClick = (data)=>{
    let classifi = findObjectInArray(categoriesData, 'value', Number(data?.category_id))
    setSelectedRow(data)
    setEditMode(true)
    setSelectedClassification(classifi)
    setEnteredKeyword(data?.visible_keyword)
    setHiddenKeyword(data?.hidden_keywords)
    setActualSelectedCategory(null)
    setSelectedCategoryChild(null)
  }
  return (
    <div className="container-child-wrapper" style={{ display: "flex" }}>
      <div className=" d-flex flex-column" style={{ flex: "0.75" }}>
        <SearchSection categoriesData={categoriesData} handleCategoryChange={handleCategoryChange} 
          selectedCategory={selectedCategory} fetchSelectedCategory={fetchSelectedCategory}
          query={query} setQuery={setQuery}
          />
        <SearchTableResult tableData={tableData} handleTableItemClick={handleTableItemClick} selectedRow={selectedRow}/>
      </div>
      <div style={{ flex: "0.25" }} className="ms-2 d-flex flex-column">
        <DynamicScrollContent outerContainerStyle={{flex: 1}} outerContainerProps={{id: 'searchSectionMain'}}>
          <div className="d-flex flex-column h-100">
            <ClassificationSection categoriesData={categoriesData} selectedClassification={selectedClassification} enteredKeyword={enteredKeyword} hiddenKeyword={hiddenKeyword}
              setEnteredKeyword={setEnteredKeyword} setHiddenKeyword={setHiddenKeyword} setSelectedClassification={setSelectedClassification} editMode={editMode} />
            <RelateWithActualKeyword categoriesData={actualCategoriesData} selectedCategory={actualSelectedCategory} selectedCategoryData={selectedCategoryData} selectedCategoryChild={selectedCategoryChild} 
              setCategoriesData={setActualCategoriesData} setSelectedCategory={setActualSelectedCategory} setSelectedCategoryData={setSelectedCategoryData} setSelectedCategoryChild={setSelectedCategoryChild} editMode={editMode}/>
            <div className='border-1 mt-2 p-2'> 
              <Input
                type="checkbox"
                label="Edit Mode"
                checked={editMode}
                onChange={(e) => {
                  setEditMode(e?.target?.checked)
                  setSelectedClassification(null)
                  setEnteredKeyword('')
                  setHiddenKeyword('')
                  setActualSelectedCategory(null)
                  setSelectedCategoryChild(null)
                }}
              />
              <div className="d-flex justify-content-between mt-2">
                <button type="submit" className="btn btn-sm btn-secondary w-100 me-1"
                  onClick={()=>{
                    
                    if(editMode){
                      setEditModalOpen(true);
                    }else{
                      setAddModalOpen(true);
                    }
                  }}
                >
                  Save
                </button>
                <button type="button" className="btn btn-sm btn-secondary w-100 me-1"
                  onClick={()=>{
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete
                </button>
                <button type="button" className="btn btn-sm btn-secondary w-100" onClick={()=>{
                  handleMenu(5, false)
                }}>
                  Exit
                </button>
              </div>
            </div>
          </div>

        </DynamicScrollContent>
      </div>
      {
      <>
      <ConfirmDialog open={editModalOpen} body={'Do you want save the edited keyword?'} handleSuccess={()=>{
        if(selectedRow?.keyword_id && enteredKeyword){
          handleEditKeyword({
            keyword_id: selectedRow?.keyword_id,
            visible_keyword: enteredKeyword,
            hidden_keyword: hiddenKeyword,
          })
        }
      }}
      handleClose={()=>{
        setEditModalOpen(false);
      }}/>
      <ConfirmDialog open={addModalOpen} body={'Do you want save the added keyword?'} handleSuccess={()=>{
        handleCreateApi({
          classification: selectedClassification?.value === 1 ? 'general_category' : selectedClassification?.value === 2 ? "main_category" : selectedClassification?.value === 3 ? "keywords" : undefined ,
          visible_keyword: enteredKeyword || undefined,
          hidden_keyword: hiddenKeyword || undefined,
          relate_actual_keyword: actualSelectedCategory?.value || undefined,
          actual_keyword: selectedCategoryChild?.value || undefined
        })
      }}
      handleClose={()=>{
        setAddModalOpen(false);
      }}/>
      <ConfirmDialog open={deleteModalOpen} body={'Do you surely want to delete the selected keyword?'}
      handleSuccess={()=>{
        handleDeleteKeyword({
          keyword_id: selectedRow?.keyword_id
        })
      }}
      handleClose={()=>{
        setDeleteModalOpen(false);
      }}/>
      {messageModal.open && <MessageDialog
        messageHeading={messageModal.heading}
        message = {messageModal.message}
        okClick = {()=>{
          setMessageModal({
            ...messageModal,
            open:false,
            messageHeading: "",
            message: ''
          })
        }}
      />}
      </>
      }
    </div>
  )
}
