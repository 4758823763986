// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-image {
    border: 1px solid #ddd;
    height: 125px;
    width: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px;
    padding: 3px;
    background: var(--color-6);
    cursor: pointer;
}

.item-image.selected{
    border: 1px solid var(--color-3);
    height: 125px;
    width: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8px;
    padding: 3px;
    background: var(--color-4);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchScreen/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".item-image {\n    border: 1px solid #ddd;\n    height: 125px;\n    width: 155px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    margin: 8px;\n    padding: 3px;\n    background: var(--color-6);\n    cursor: pointer;\n}\n\n.item-image.selected{\n    border: 1px solid var(--color-3);\n    height: 125px;\n    width: 155px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    margin: 8px;\n    padding: 3px;\n    background: var(--color-4);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
