import { useState, useEffect } from 'react';

const useKeyPressStatus = () => {
  const [pressedKeys, setPressedKeys] = useState([]);

  const handleKeyDown = (event) => {
    setPressedKeys((prevKeys) => {
      // if (!prevKeys.includes(event.key)) {
      //   return [...prevKeys, event.key];
      // }
      // return prevKeys;
      return [event.key];
    });
  };

  const handleKeyUp = (event) => {
    setPressedKeys([]);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return pressedKeys;
};

export default useKeyPressStatus;
