import React, { useState, useEffect, useRef } from "react"
import "../App.css"
import WindowCreater from "../components/HOC/WindowCreater"
import { useAppContext } from "../context/AppContext"
import SearchSection from "../components/RulesScreen/SearchSection"
import RenderCategory from "../components/CommonComp/RenderCategory"
import { getKeywordsCateogyListing } from "../services/EnterNewKeyword"
import { computeLoaderState, findObjectInArray, findObjectIndexInArray, getArrayOfObjJoinToStringForKey } from "../lib/helper"
import { createCoExistance, getCoExistanceList } from "../services/RulesApi"
import MessageDialog from "../components/CommonComp/modals/MessageDialog"

function RulesDefineScreen({ itemContainer, containerIndex, bringToFront }) {
  const { handleMenu } = useAppContext()
  const [loading, setLoading] = useState({
    submit: false
  });
  return (
    <WindowCreater
      title={itemContainer?.title}
      x={itemContainer?.x}
      y={itemContainer?.y}
      id={itemContainer?.id}
      onBringToFront={bringToFront}
      handleCloseWindow={() => {
        handleMenu(6, false)
      }}
      loading={computeLoaderState(loading)}
      style={itemContainer?.style}
      {...itemContainer}
    >
      {
        <ChildComponent loading={loading} setLoading={setLoading} />
      }
    </WindowCreater>
  )
}

export default RulesDefineScreen

const ChildComponent = ({isMaximized, isMinimized, position, parentDimensions, loading, setLoading, windowRef}) => {
    const [resetData, setResetData] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedAllKeyword, setSelectedAllKeyword] = useState(null)
    const [noKeywordList, setNoKeywordList] = useState([])
    const [shouldRestScroll, setShouldRestScroll] = useState(false)
    const [allKeywordData, setAllKeywordData] = useState([])
    const [allKeywordForSelect, setAllKeywordForSelect] = useState([])
    const [showAssociateOnly, setShowAssociateOnly] = useState(false)
    const [MessageDialogOpen, setMessageDialogOpen] = useState(false);
    const [checkedKeywordList, setCheckedKeywordList] = useState([]);
    async function fetchSelectedCategory(payload) {
      let data = await getKeywordsCateogyListing(payload)
      setAllKeywordData(data)
      let newData = data?.map((el)=>{
        return {
          label: el?.visible_keyword,
          value: el?.visible_keyword,
          item: el
        }
      })
      setAllKeywordForSelect(newData)
    }
    useEffect(()=>{
      const checkAllKeywords = allKeywordData?.filter((el)=>{
        return el?.isChecked
      })
      setCheckedKeywordList(checkAllKeywords);
    },[allKeywordData]);
    const saveChanges = async ()=>{
      if(selectedCategory?.value){
        setLoading({
          ...loading,
          submit: true
        })
        let coExistKeyword = noKeywordList.length ? getArrayOfObjJoinToStringForKey(noKeywordList, 'visible_keyword', ",") : undefined;
        if(checkedKeywordList.length > 0 && coExistKeyword == undefined){
          setMessageDialogOpen({
            open:true,
            message: 'Please add keywords to "Not Co-Exist Keyword List"!',
          });
          setLoading({
            ...loading,
            submit: false
          })
          return;
        }
        const resp = await createCoExistance({
          keyword: selectedCategory?.value,
          co_existance_keyword: coExistKeyword
        })
        setLoading({
          ...loading,
          submit: false
        })
      }else{
        setLoading({
          ...loading,
          submit: false
        })
        setMessageDialogOpen({
          open:true,
          message: 'Please select keyword!',
        });
      }
    }

    const handleCheckBox = async (value)=>{
      setShowAssociateOnly(value)
      if(value === false){
        let newData = [...allKeywordData]?.map((el)=>{
          return {
            label: el?.visible_keyword,
            value: el?.visible_keyword,
            item: el
          }
        })
        setAllKeywordForSelect(newData)
        setSelectedCategory(null)
      }else{
        let data = await fetchSelectedCategoryCategory()
        let newData = data?.map((item, index)=>{
          let objInd = findObjectIndexInArray(allKeywordData, "visible_keyword", item?.keyword)
          return {
            label: allKeywordData[objInd]?.visible_keyword,
            value: allKeywordData[objInd]?.visible_keyword,
            item: allKeywordData[objInd]
          }
        })
        setAllKeywordForSelect(newData)
      }
    }

    async function fetchSelectedCategoryCategory(keyword="") {
      let data = await getCoExistanceList({
        keyword: keyword,
      })
      return data
    }
  
    const handleCategoryChange = async(data)=>{
      setSelectedCategory(data)
      let dataNew = await fetchSelectedCategoryCategory(data?.value)

      let updateAllKeyword = [...allKeywordData]
      updateAllKeyword = updateAllKeyword?.map((el, ind)=>{
        return {
          ...el,
          isChecked: false
        }
      })
      dataNew?.map((ele, ind)=>{
        let objInd = findObjectIndexInArray(updateAllKeyword, "visible_keyword", ele?.co_existance_keyword)
        if(objInd > -1){
          updateAllKeyword[objInd]["isChecked"] = true
        }
      })
      setAllKeywordData(updateAllKeyword)
      handleSelectedWords(updateAllKeyword)
    }
   

    useEffect(()=>{
      fetchSelectedCategory({category_id: 3})
    },[])

    useEffect(()=>{
      if(resetData){
        setSelectedCategory([])
      }
    },[resetData])

    const handleSelectedWords = (keywordState=allKeywordData) => {
      const checkAllKeywords = keywordState?.filter((el)=>{
        return el?.isChecked
      })
      setNoKeywordList(checkAllKeywords);
      setCheckedKeywordList(checkAllKeywords);
    };

    const handleClick = (e, index, el) => {
      if (e.detail % 2 !== 0) {
     
      } else if (e.detail % 2 === 0) {
        handleRemove(index, el)
      }
    }

    const handleRemove = (index, el) => {
      let copyArr = [...noKeywordList]
      copyArr.splice(index, 1)
      setNoKeywordList(copyArr)
      
      let data = [...allKeywordData]
      const findIndex = findObjectIndexInArray(data, 'visible_keyword', el["visible_keyword"])
      data[findIndex]['isChecked'] = data[findIndex]['isChecked'] ? !data[findIndex]['isChecked'] : true
      setAllKeywordData(data)
    }

    return <div className="container-child-wrapper row">
      <div className="col-12 d-flex flex-column">
        <div>
          <SearchSection
            resetData={resetData}
            categoriesData={allKeywordForSelect}
            setResetData={setResetData}
            handleCategoryChange={handleCategoryChange}
            selectedCategory={selectedCategory}
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
            windowRef={windowRef}
            saveChanges={saveChanges}
            handleCheckBox={handleCheckBox}
            showAssociateOnly={showAssociateOnly}
          />
        </div>
        <div className="row g-2 h-100" id="keyword-list-container" style={{ flex: 1 }}>
          <RenderCategory
            categories={allKeywordData}
            selected={selectedAllKeyword}
            labelHeading='Select "No Co-Exist" Keyword'
            rowDisabled={true}
            parentClassname="col-5"
            onClick={(el, index, ref) => {
              let data = [...allKeywordData]
              const findIndex = findObjectIndexInArray(data, 'visible_keyword', el["visible_keyword"])
              data[findIndex]['isChecked'] = data[findIndex]['isChecked'] ? !data[findIndex]['isChecked'] : true
              setAllKeywordData(data)
              setSelectedAllKeyword(data[findIndex])
            }}
            onCheckboxClick={(e, el, index) => {}}
            enableCheckBox={true}
            objAccessKey={"visible_keyword"}
            accessKeyValue={'keyword_id'}
            id="allKeyword"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
          <div className="col-2 d-flex justify-content-center align-items-center">
            <button
              className="btn btn-sm btn-secondary me-2"
              onClick={()=>handleSelectedWords(allKeywordData)}
            >
              {">>"}
            </button>
          </div>
          <RenderCategory
            categories={noKeywordList}
            rowDisabled={true}
            parentClassname="col-5"
            labelHeading="Not Co-Exist Keyword List"
            onClick={(el, index, ref, e) => handleClick(e, index, el)}
            objAccessKey={"visible_keyword"}
            shouldRestScroll={shouldRestScroll}
            setShouldRestScroll={setShouldRestScroll}
            id="nocoexistlist"
            isMaximized={isMaximized}
            isMinimized={isMinimized}
            position={position}
            parentDimensions={parentDimensions}
          />
        </div>
      </div>
      {(MessageDialogOpen.open)?<MessageDialog message={MessageDialogOpen.message} okClick={()=>{
        setMessageDialogOpen({
          open:false,
          message: '',
        });
      }}
      />:'' }
    </div>

}
