import React, { useState, useRef, useEffect } from "react"
import { getVisibleKeywordListingNoRedux } from "../../services/keywordsApis"
import { findObjectIndexInArray, getArrayOfObjJoinToStringForKey } from "../../lib/helper"
import Input from "../CommonComp/Input"
import Select from "../CommonComp/Select"

function SearchSection({ setActualKeywords=()=>{}, selectedResults=[], setSelectedResults=()=>{},
  categoriesData=[], resetData=false, setResetData=()=>{}, selectedCategory=null, handleCategoryChange=()=>{},
  isMaximized, parentDimensions, fetchSelectedCategory=()=>{}, query="", setQuery=()=>{}
}) {
    
    useEffect(()=>{
      if(resetData){
          setSelectedResults([])
          setActualKeywords([])
          setQuery("")
          setResetData(false)
      }
    },[resetData])
  

  
    function searchKeywords(e) {
      let value = e.target.value
      setQuery(value)
    }
 
  return (
    <div className="container mb-2">
      <div className="row border-1 px-2 py-2">
        <div className="col-sm">
          <Select label={"Search Criteria"} 
            options={categoriesData} labelProps={{style:{
              width: 125
            }}}
            value={selectedCategory}
            onChange={handleCategoryChange}
          />
        </div>
        <div className="col-sm">
          <Input
            label={"Search Value"}
            value={query}
            id="search-input"
            placeholder="Search"
            mainContainerProps={{
              className: "d-flex position-relative",
            }}
            onChange={(e) => searchKeywords(e)}
            labelProps={{style:{
              width: 125
            }}}
            autoComplete="off"
          />
        </div>
        <div className="col-2 text-end">
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => {
              if(selectedCategory){
                fetchSelectedCategory({
                  category_id: selectedCategory?.value,
                  search: query || ""
                })
              }
              // handleResultSelection(selectedIndex)
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>

  )
}

export default SearchSection
