import React from 'react'

const DynamicScrollContent =  React.forwardRef(({outerContainerStyle={}, outerContainerProps={}, internalContainerProps, ...props}, ref) => {
  return (
    <div style={{ flex: 0.75, ...outerContainerStyle }} {...outerContainerProps} className={`position-relative ${outerContainerProps?.className}`}>
        <div ref={ref} {...internalContainerProps} className={`scroll-container-style ${internalContainerProps?.className}`}>
            {props.children}
        </div>
    </div>
  )
})

export default DynamicScrollContent