import React, { useState, useRef, useEffect } from "react"
import "./windowCreater.css"

const WindowCreater = ({
  handleCloseWindow = () => {},
  id,
  title,
  x,
  y,
  resizable = false,
  pWidth = 1200,
  pHeight = 600,
  onBringToFront,
  loading=false,
  ...props
}) => {
  const windowRef = useRef(null)
  const [isMaximized, setIsMaximized] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const [position, setPosition] = useState({ x, y })
  const [parentDimensions, setParentDimensions] = useState({
    width: 0,
    height: 0,
  })
  const handleMaximize = () => {
    const windowElement = windowRef.current

    if (isMaximized) {
      // Restore to original size
      windowElement.style.width = pWidth + "px" // Set your original width
      windowElement.style.height = pHeight + "px" // Set your original height
      //   windowElement.style.top = '50px'; // Set your original top position
      //   windowElement.style.left = '50px'; // Set your original left position
    } else {
      // Maximize to parent size
      const parentElement = windowElement.parentElement
      const parentWidth = parentElement.clientWidth
      const parentHeight = parentElement.clientHeight
      setPosition({ x: 0, y: 0 })
      windowElement.style.width = `${parentWidth}px`
      windowElement.style.height = `${parentHeight}px`
      //   windowElement.style.top = '0';
      //   windowElement.style.left = '0';
    }

    setIsMaximized(!isMaximized)
    setIsMinimized(false)
  }

  const handleMinimize = () => {
    const windowElement = windowRef.current

    if (!isMinimized) {
      // Minimize the window
      windowElement.style.height = "31px"
      windowElement.style.overflow = "hidden"
      setPosition({ x: 0, y: 0 })
    } else {
      // Restore from minimize
      windowElement.style.height = pHeight + "px"
      windowElement.style.overflow = "hidden"
    }

    setIsMinimized(!isMinimized)
    setIsMaximized(false)
  }

  const handleClose = () => {
    // You can add any cleanup logic before closing the window
    // For example, save user data, prompt for confirmation, etc.
    const windowElement = windowRef.current
    windowElement.style.display = "none"
    handleCloseWindow()
  }

  useEffect(() => {
    // Get the dimensions of the parent container
    const parentContainer = windowRef.current.parentElement
    const parentWidth = parentContainer.clientWidth
    const parentHeight = parentContainer.clientHeight
    setParentDimensions({ width: parentWidth, height: parentHeight })
  }, [])

  const handleMouseDown = (e) => {
    e.stopPropagation();
    
    let mouseDownTime = new Date().getTime();
    const isContentOrDescendant = e.target.closest('.content');
    // Bring the window to the front when clicked
    onBringToFront(id)

    // Save initial mouse coordinates and window position
    const initialMouseX = e.clientX
    const initialMouseY = e.clientY
    const initialX = position.x
    const initialY = position.y

    const handleMouseMove = (e) => {
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - mouseDownTime;
      if (timeElapsed > 200 && !isContentOrDescendant) {
        // Calculate new window position
        let newX = initialX + (e.clientX - initialMouseX)
        let newY = initialY + (e.clientY - initialMouseY)
        const element = windowRef?.current // Replace with your actual element ID
        const rect = element.getBoundingClientRect()
        let initialWidth = rect.width || 1200
        let initialHeight = rect.height || 600
        element.style.transform = `scale(1.004)`
        element.style.boxShadow = `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`
        // Constrain the position within the parent container
        newX = Math.max(0, Math.min(newX, parentDimensions.width - initialWidth)) // Adjust 200 based on window width
        newY = Math.max(
          0,
          Math.min(newY, parentDimensions.height - initialHeight)
        ) // Adjust 150 based on window height

        setPosition({ x: newX, y: newY })
      }
    }

    const handleMouseUp = () => {
      // Remove event listeners when the mouse is released
      windowRef.current.style.transform = ``
      windowRef.current.style.boxShadow = `0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("mouseup", handleMouseUp)
    }

    // Attach event listeners for dragging
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("mouseup", handleMouseUp)
  }

  return (
    <div
      ref={windowRef}
      className={`draggable-window ${isMaximized ? "maximized" : ""} ${
        isMinimized ? "minimized" : ""
      } ${resizable?"resizable":''}`}
      id={`draggable-window-${id}`}
      style={{
        left: position.x,
        top: position.y,
        zIndex: props?.style?.zIndex,
        width: pWidth,
        height: pHeight
      }}
      onMouseDown={handleMouseDown}
    >
      <div className="title-bar">
        <div className="window-title">{title}</div>
        <div className="window-buttons">
          <button onClick={handleMinimize}>{isMinimized ? "R" : "-"}</button>
          <button onClick={handleMaximize}>{isMaximized ? "R" : "□"}</button>

          <button onClick={handleClose}>✕</button>
        </div>
      </div>
      <div className="content">
        {/* Add your window content here */}
        {/* {props.children} */}
        {loading && <div className="loader-container">
          <div class="custom-loader"><div></div><div></div><div></div></div>
        </div>}
        {React.Children.map(props.children, (el) => React.cloneElement(el, {
            isMinimized: isMinimized,
            isMaximized: isMaximized,
            position: position, 
            parentDimensions: parentDimensions,
            windowRef: windowRef?.current
          })
        )}
      </div>
    </div>
  )
}

export default WindowCreater
