import React from 'react'
import './style.css'
function ConfirmDialog({open=false, header='Alert', body=null, handleSuccess=()=>{}, handleClose=()=>{}}) {
    if(!open){
        return null
    }
    return (
        <div className='dialog-ovelay'>
            <div id='confirmationDialog' className='dialog'>
                <header>{header}</header>
                {body &&<div className='p-3'>
                    {body}
                </div>}
                <footer>
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary me-2 w-25 confirm-SubmitBtn"
                        onClick={() => {
                            handleSuccess(false)
                        }}
                    >
                        Submit
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-danger  w-25"
                        onClick={() => {
                            handleClose(false)
                        }}
                    >
                        Close
                    </button>
                </footer>
            </div>
        </div>
    )
}

export default ConfirmDialog