// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --color-1: rgb(201 233 224);
    --color-2: rgb(36, 101, 75);
    --color-3: rgb(151 197 184);
    --color-4: rgb(224, 234, 195);
    --color-5: #009688;
    --color-6: #efefef;
    --color-7: #3d3d3d;
}`, "",{"version":3,"sources":["webpack://./src/Asset/css/color.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;IAC3B,6BAA6B;IAC7B,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[":root {\n    --color-1: rgb(201 233 224);\n    --color-2: rgb(36, 101, 75);\n    --color-3: rgb(151 197 184);\n    --color-4: rgb(224, 234, 195);\n    --color-5: #009688;\n    --color-6: #efefef;\n    --color-7: #3d3d3d;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
