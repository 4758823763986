import React, { useEffect, useState } from 'react';
import Select from '../CommonComp/Select';

function FolderSection({
  folderData = [],
  selectedFolder = {},
  handleFolderChange = () => {},
  handleRefresh = () => {},
  handleGoClick = () => {},
  handleAdvanceSearchClick = () => {},
}) {
    let [enterkeyPressed, setEnterKeyPressed] = useState(0);
  const runOnEnter = (e) => {
    if (e.key == 'Enter') {
      let enterKeyCount = enterkeyPressed;
      enterKeyCount++;
      setEnterKeyPressed(enterKeyCount);
      
    }
  };
  useEffect(()=>{
    if(enterkeyPressed == 1){
        handleGoClick(selectedFolder);
        setEnterKeyPressed(0);
    }
  },[enterkeyPressed]);
  
  return (
    <>
      <div
        style={{
          background: 'var(--color-4)',
        }}
        className="m-1 mb-0 p-1 d-flex align-items-center"
      >
        <button
          type="button"
          className="btn btn-sm btn-secondary me-1 font12"
          onClick={() => handleRefresh(selectedFolder)}
        >
          R
        </button>
        <div className="font12">Folder</div>
        <div className="font12" style={{ flex: 1 }}>
          <Select
            options={folderData}
            mainContainerProps={{
              className: 'd-flex m-0 ms-1',
            }}
            value={selectedFolder}
            onChange={(e) => {
              handleFolderChange(e);
            }}
            isSearchable={true}
            onKeyDown={(e) => {
              runOnEnter(e);
            }}
          />
        </div>
        <button
          type="button"
          className="btn btn-sm btn-secondary ms-1 font12"
          onClick={() => handleGoClick(selectedFolder)}
        >
          Go
        </button>
      </div>
      <div
        style={{
          background: 'var(--color-4)',
        }}
        className="m-1 mt-0 p-1 d-flex align-items-center justify-content-end"
      >
        <button
          type="button"
          className="btn btn-sm btn-secondary me-1 font12"
          onClick={() => handleAdvanceSearchClick(selectedFolder)}
        >
          Advance Search
        </button>
      </div>
    </>
  );
}

export default FolderSection;
