// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-ovelay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 999999
}

.dialog-ovelay .dialog {
    width: 300px;
    margin: 100px auto 0;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    border-radius: 3px;
    overflow: hidden
}

.dialog-ovelay .dialog header {
    padding: 10px 10px;
    background-color: #f6f7f9;
    /* border-bottom: 1px solid #e5e5e5 */
}

.dialog-ovelay .dialog footer {
    padding: 10px 10px;
    background-color: #f6f7f9;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/components/CommonComp/modals/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC;AACJ;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,sBAAsB;IACtB,mCAAmC;IACnC,kBAAkB;IAClB;AACJ;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gCAAgC;IAChC,aAAa;IACb,oBAAoB;AACxB","sourcesContent":[".dialog-ovelay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.50);\n    z-index: 999999\n}\n\n.dialog-ovelay .dialog {\n    width: 300px;\n    margin: 100px auto 0;\n    background-color: #fff;\n    box-shadow: 0 0 20px rgba(0,0,0,.2);\n    border-radius: 3px;\n    overflow: hidden\n}\n\n.dialog-ovelay .dialog header {\n    padding: 10px 10px;\n    background-color: #f6f7f9;\n    /* border-bottom: 1px solid #e5e5e5 */\n}\n\n.dialog-ovelay .dialog footer {\n    padding: 10px 10px;\n    background-color: #f6f7f9;\n    border-bottom: 1px solid #e5e5e5;\n    display: flex;\n    justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
