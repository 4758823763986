import React, { useEffect, useRef, useState } from "react"
import DynamicScrollContent from "../HOC/DynamicScrollContent";
import Input from "./Input";
import { Virtuoso } from "react-virtuoso";

const RenderCategory = React.forwardRef(({categories=[], selected=null, onClick, objAccessKey=null,shouldRestScroll=false, id="", 
  setShouldRestScroll=()=>{}, isMaximized=false, accessKeyValue="category_keyword_id", enableCheckBox=false, onCheckboxClick=()=>{}, labelHeading = '',
  rowDisabled=false, parentClassname='', parentStyle={}, virtuosoStyle={}, doubleClickFunctionality=()=>{}, ...props}, ref) => {
  useEffect(()=>{
    if(shouldRestScroll){
      let pelement = document.getElementById(`container-with-scroll-child`)
      let element = pelement.querySelector('[data-test-id="virtuoso-scroller"]');
      if(element){
        element.scrollTo(0,0);
      }
      setShouldRestScroll(false)
    }
  },[shouldRestScroll])

  if(!objAccessKey){
    return null;
  }

  return (
    <div className={`${!rowDisabled ? "col-3" : ""} selected-keyword d-flex flex-column ${parentClassname}`} style={parentStyle}>
      {labelHeading && <div className="font12 fontWeightBold mb-1">{labelHeading}</div>}
      <DynamicScrollContent outerContainerStyle={{flex: 1}} internalContainerProps={{
        className: 'border-1',
        id:`container-with-scroll-${id}`,
      }}>
        <Virtuoso
          style={{ height: '100%', ...virtuosoStyle }}
          data={categories}
          ref={ref}
          itemContent={(index, el) => {
          return <div
              key={el[accessKeyValue]}
              id={"ul_li_child" + el[accessKeyValue]}
              className={`size-13 selected-list ${selected && (selected[accessKeyValue] === el[accessKeyValue]) ? "active-element" : ""}`}
              style={{ cursor: "pointer", display: "flex", alignItems: 'center', padding: '1px 6px', lineHeight: 1.4, fontWeight: 600 }}
              onClick={(e) => {
                onClick(el, index, ref, e)
              }}
              onDoubleClick={(e)=>{
                doubleClickFunctionality(el, index, ref, e);
              }}
              {...props}
            >
              {enableCheckBox && <Input
                  type="checkbox"
                  checked={el?.isChecked}
                  onChange={(e) => onCheckboxClick(el, index)}
                  mainContainerProps={{
                    style:{
                      minHeight: 'auto'
                    }
                  }}
                  
              />}
              <div
                style={{
                  flex: 1,
                  wordBreak: "break-word",
                  // paddingRight: 10,
                  display: "flex",
                  alignItems: "center",
                  userSelect: "none",
                  lineHeight: '1.2'
                }}
                className="truncate-string"
                onDoubleClick={doubleClickFunctionality}
                // className="p-1"
              >
                {el[objAccessKey]}
              </div>
            </div>
          }}
        />
      </DynamicScrollContent>
    </div>
  )
})

export default RenderCategory
