import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getVisibleKeywordListingNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.visibleKeyword}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data?.rows
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
  }
};

export const getCategories = async () => {
    try {
      const resp = await axios.get(`${ApiConfig.categoryList}`);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};
export const getNonRelatedKeywords = async () => {
    try {
      const resp = await axios.post(`${ApiConfig.nonRelatedList}`);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const getCategorykeywordlist = async (payload) => {
  if(payload){
    try {
      const resp = await axios.post(`${ApiConfig.categorykeywordlist}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
  }
};
export const postImageKeywords = async (payload) => {
  if(payload){
    try {
      const resp = await axios.post(`${ApiConfig.imagekeywords}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return {
          status: true,
          message: resp.data.message
        }
      } else {
        throw resp;
      }
    } catch (e) {
      return {
        status: false,
        message: e.data.message
      }
    }
  }
};