import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const exportImagesApi = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.exportImages}`, payload, {
        responseType: 'arraybuffer'
      });
      if (
        resp?.status >= 200 &&
        resp?.status < 400
      ) {
        return resp?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return false
    }
};