import React from 'react'
import Input from '../CommonComp/Input';

function ImageRange({ imageFrom='', setImageFrom=()=>{}, imageTo='', setImageTo=()=>{}, handleSearchSubmit=()=>{}, folderName=null }) {
  return (<div  style={{ background: 'var(--color-4)'}} className='py-1 m-1'>
        <div className='font13 mx-1 fontWeightBold d-flex justify-content-between'>
            {/* Image Search  */}
            {(folderName && imageFrom && !imageTo) && <div>Folder Name: {folderName}</div>}    
        </div>
        <div className='m-2 d-flex align-items-center'>
            <div className='font12'>From</div>
            <div className='font12' style={{flex: 1}}>
                <Input 
                    mainContainerProps={{
                        className: 'd-flex m-0 ms-1'
                    }}
                    value={imageFrom}
                    onChange={(e)=>{
                        setImageFrom(e.target.value)
                    }}
                />
            </div>
            <div className='font12  ms-1'>To</div>
            <div className='font12' style={{flex: 1}}>
                <Input 
                    mainContainerProps={{
                        className: 'd-flex m-0 ms-1'
                    }}
                    value={imageTo}
                    onChange={(e)=>{
                        setImageTo(e.target.value)
                    }}
                />
            </div>
        </div>
        <button
            type="button"
            className="btn btn-sm btn-secondary ms-2 mb-1 font12"
            onClick={()=>handleSearchSubmit(imageFrom, imageTo)}
        >Search</button>
    </div>)
}

export default ImageRange