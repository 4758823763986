import React, { useEffect, useRef, useState } from "react"
import Input from "../CommonComp/Input"
import RenderCategory from "../CommonComp/RenderCategory"
import { getSearchCategoryChildData } from "../../services/RelationshipApis"

function RightSection({
  actualKeywords,
  setSelectedWords = () => {},
  isMaximized,
  parentDimensions,
  loading, setLoading
}) {

  const [searchedValue, setSearchValue] = useState('')
  const [searchedCategoryChild, setSearchCategoryChild] = useState([])
  const searchChildRef = useRef(null)

  useEffect(() => {
    let newString = actualKeywords?.length ? actualKeywords?.join(",") : ""
    setSelectedWords(newString)
  }, [actualKeywords])


  async function fetchSearchCategoryChildData(value) {
    if(value){
      setLoading({
        ...loading,
        submit: true
      })
      let data = await getSearchCategoryChildData({
        keyword_name: value,
        // category_name: selectedCategory?.value === 1 ? 'General Category' : selectedCategory?.value === 2 ? 'Main Category' : undefined
      })
      if(data?.length){
        setSearchCategoryChild(data);
      }else{
        setSearchCategoryChild([]);
      }
      setLoading({
        ...loading,
        submit: false
      })
    }else{
      setSearchCategoryChild([]);
    }
  }

  return (
    <>
      <div className="d-flex flex-column border-1 p-2" style={{flex: 1}}>
        <h6
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="font13"
        >
          Keyword Related to
        </h6>
        <div className="d-flex flex-column ">
          <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Keywords</div>
          <div>
              <Input
                  value={searchedValue}
                  id="keyword-input"
                  placeholder="Keywords"
                  mainContainerProps={{
                      className: "d-flex mb-3 position-relative",
                  }}
                  onChange={(e) => {
                    setSearchValue(e?.target?.value)
                  }}
                  onKeyUp={(e)=>{
                    if(e.key == 'Enter'){
                      fetchSearchCategoryChildData(searchedValue)
                    }
                  }}
                  labelProps={{style:{
                      width: 125
                  }}}
                  autoComplete="off"
              />
          </div>
        </div>
        <RenderCategory
          categories={searchedCategoryChild}
          ref={searchChildRef}
          // selected={selectedNotRelatedCategory}
          labelHeading="Related With"
          onClick={(e, el, index, averageHeight, ref) => {
            // setSelectedNotRelatedCategory(el)
            // handleChildListClick(el, averageHeight, "visible_keyword", 1)
          }}
          parentStyle={{flex: 1}}
          rowDisabled
          objAccessKey={"parent_name"}
          // accessKeyValue={'parent_name'}
          id="related-with"
          isMaximized={isMaximized}
          parentDimensions={parentDimensions}
        />
      </div>
    </>
  )
}

export default RightSection
