import React from "react"
import CustomSelect from "./CustomSelect"

const Select = React.forwardRef(({
  label = null,
  labelPlacement = "left",
  labelProps={},
  innerContainerProps = {},
  mainContainerProps = {},
  children,
  selectChildren,
  ...props
}, ref) => {
  return (
    <div {...mainContainerProps}  className={`d-flex ${mainContainerProps?.className}`} >
      {label && labelPlacement === "left" && (
        <label
          className="col-form-label me-2 size-12"
          style={{ lineHeight: 1 }}
          {...labelProps}
        >
          {label}
        </label>
      )}
      <div className="" style={{ flex: 1 }} {...innerContainerProps}>
        <CustomSelect ref={ref} {...props}/>
      </div>
      {label && labelPlacement === "right" && (
        <label
          className="col-form-label me-2 size-12"
          style={{ lineHeight: 1 }}
          {...labelProps}
        >
          {label}
        </label>
      )}
      {children&& children}
    </div>
  )
})

export default Select
