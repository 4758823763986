// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add any global styles here */

.draggable-window {
    position: absolute;
    border: 1px solid #ccc;
    width: 1200px;
    margin: auto;
    height: 600px;
    /* max-height: 600px; */
    overflow: hidden;
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.title-bar {
    background-color: var(--color-5);
    color: #fff;
    padding: 4px 8px;
    cursor: move;
    display: flex;
    justify-content: space-between;
}

.window-title {
    flex-grow: 1;
    font-size: 14px;
    font-weight: bold;
}

.window-buttons{
    display: flex;
    align-items: center;
}

.window-buttons button {
    background: none;
    border: none;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    padding: 0 10px;
    cursor: pointer;
}

.content {
    padding: 10px;
    flex: 1 1;
    position: relative;
}

/* Add any other styles as needed */`, "",{"version":3,"sources":["webpack://./src/components/HOC/windowCreater.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,UAAU;IACV,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,4EAA4E;AAChF;;AAEA;IACI,gCAAgC;IAChC,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,kBAAkB;AACtB;;AAEA,mCAAmC","sourcesContent":["/* Add any global styles here */\n\n.draggable-window {\n    position: absolute;\n    border: 1px solid #ccc;\n    width: 1200px;\n    margin: auto;\n    height: 600px;\n    /* max-height: 600px; */\n    overflow: hidden;\n    padding: 0;\n    background-color: #fff;\n    display: flex;\n    flex-direction: column;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\n.title-bar {\n    background-color: var(--color-5);\n    color: #fff;\n    padding: 4px 8px;\n    cursor: move;\n    display: flex;\n    justify-content: space-between;\n}\n\n.window-title {\n    flex-grow: 1;\n    font-size: 14px;\n    font-weight: bold;\n}\n\n.window-buttons{\n    display: flex;\n    align-items: center;\n}\n\n.window-buttons button {\n    background: none;\n    border: none;\n    color: #fff;\n    font-size: 14px;\n    margin-left: 5px;\n    padding: 0 10px;\n    cursor: pointer;\n}\n\n.content {\n    padding: 10px;\n    flex: 1;\n    position: relative;\n}\n\n/* Add any other styles as needed */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
