import React, {useEffect, useRef, useState } from "react"
import Input from "../CommonComp/Input"
import { deleteSelectedImages, wordManupilation } from "../../services/SearchScreenApis"
import Select from "../CommonComp/Select"

function WordManupulationSection({ allKeywordForSelect=[], imagesSelected, setFetchKeywordsStatus=()=>{}, loading={}, setLoading=()=>{}, handleDeleteImages=()=>{}, wordManipulationResponse, setWordManipulationResponse,
filterFunction = () => {}}) {
  const [findWord, setFindWord] = useState('')
  const [replace, setReplace] = useState('')
  const [enableDelete, setEnableDelete] = useState(false)
 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedReplaceCategory, setSelectedReplaceCategory] = useState(null);
  const selectRef = useRef(null);
  const categoryChangeRef = useRef(null);
  const addRef = useRef(null);
  const removeRef = useRef(null);
  const replaceRef = useRef(null);

  const handlewordManupilation = async (imagesSelected=[], fWord="", rplace="", action=null) => {
    if(imagesSelected?.length && action){
      setLoading({
        ...loading,
        submit: true
      })
      let resp = await wordManupilation({
        image: imagesSelected?.join(','),
        ...(fWord && {keyword: fWord}),
        ...(rplace && {replacewith: rplace}),
        action: action
      });
      setFetchKeywordsStatus({
        status: true,
        from: "normal"
      })
      setLoading({
        ...loading,
        submit: false
      })
    }
  }


  const handleCategoryChange = (data) => {
    setSelectedCategory(data);
    selectRef.current.focus();
  }
  const handleReplaceCategoryChange = (data) => {
    setSelectedReplaceCategory(data);
    categoryChangeRef.current.focus();
  }

  useEffect(()=>{
    if(wordManipulationResponse?.confirm == true){
      if(wordManipulationResponse?.action == 'add'){
        setWordManipulationResponse({
          ...wordManipulationResponse,
          confirm: false,
          open: false,
        })
        handlewordManupilation(imagesSelected, selectedCategory?.value, selectedReplaceCategory?.value, 'add');
        // selectRef.current.focus();
        addRef.current.focus();
      }
      else if(wordManipulationResponse?.action == 'remove'){
        setWordManipulationResponse({
          ...wordManipulationResponse,
          confirm: false,
          open: false,
        })
        handlewordManupilation(imagesSelected, selectedCategory?.value, selectedReplaceCategory?.value, 'remove');
        // selectRef.current.focus();
        removeRef.current.focus();
      }
      else if(wordManipulationResponse?.action == 'replace'){
        setWordManipulationResponse({
          ...wordManipulationResponse,
          confirm: false,
          open: false,
        })
        handlewordManupilation(imagesSelected, selectedCategory?.value, selectedReplaceCategory?.value, 'replace');
        // selectRef.current.focus();
        replaceRef.current.focus();
      }
      else{
        setWordManipulationResponse({
          ...wordManipulationResponse,
          confirm: false,
          open: false,
        })
        selectRef.current.focus();
      }
      // selectRef.current.focus();
    }
  }, [wordManipulationResponse.confirm])
  const runOnCopy1 = (e) => {
    if (e.key == 'Enter') {
      selectRef.current.focus();
    }else if(e.key == 'c' && (e.ctrlKey)){
      copyToClipboard(selectedCategory?.value);
    }
  }
  const runOnCopy2 = (e) => {
    if (e.key == 'Enter') {
      categoryChangeRef.current.focus();
    }else if(e.key == 'c' && (e.ctrlKey)){
      copyToClipboard(selectedReplaceCategory?.value);
    }
  }
  const copyToClipboard = (itemToCopy) =>{
    navigator.clipboard.writeText(itemToCopy).then(()=>{
      showSimpleToast(itemToCopy);
    },()=>{
      alert("Copying to clipboard failed. Please try again.");
    });
  }
  const showSimpleToast = (copiedText) =>{
    let toastElement = document.createElement('p');  
    toastElement.id = 'copiedTextToast';
    toastElement.classList.add('px-5', 'py-3', 'rounded', 'bg-dark');
    toastElement.style.cssText += 'position: fixed; z-index: 2; color: #fff; top: 10px;left: 10px;';
    toastElement.innerHTML = 'Value copied: "' + copiedText + '"';
    document.body.appendChild(toastElement);
    setTimeout(() => {
      let element = document.getElementById('copiedTextToast');
      element.remove();
    }, 2000);
  }
  return (
    
    <div style={{ background: "var(--color-4)" }} className="py-1 m-1">
      {/* <div className="font13 ms-1 fontWeightBold">Words Manupulation</div> */}
      <div className="m-2 mt-3 ">
        <div className="font12 fontWeightBold" style={{ flex: 1 }}>Find Word in Selected Image(s)</div>
        <div className="custom-select-height">
          <Select
            options={allKeywordForSelect}
            labelProps={{
              style: {
                width: 125,
              },
            }}
            value={selectedCategory}
            onChange={handleCategoryChange}
            onKeyDown={(e) => {
              runOnCopy1(e);
            }}
            mainContainerProps={{
              className: "mb-3",
            }}
            isSearchable={true}
            isClearable
            ref={selectRef}
            filterOption={filterFunction}
          />
        </div>
      </div>
      <div className="m-2 mt-3 ">
        <div className="font12 fontWeightBold" style={{ flex: 1 }}>Replace With</div>
        <div className="custom-select-height">
            <Select
              options={allKeywordForSelect}
              labelProps={{
                style: {
                  width: 125,
                },
              }}
              value={selectedReplaceCategory}
              onChange={handleReplaceCategoryChange}
              mainContainerProps={{
                className: "mb-3",
              }}
              isSearchable={true}
              isClearable
              filterOption={filterFunction}
              onKeyDown={(e) => {
                runOnCopy2(e);
              }}
              ref={categoryChangeRef}
            />
        </div>
      </div>
     
      <div className="d-flex justify-content-between mx-2 mt-3">
        <button
          type="button"
          ref={addRef}
          className="btn btn-full btn-sm btn-secondary  mb-1 me-1 font12 w-100"
          onClick={() => {
            setWordManipulationResponse({
              ...wordManipulationResponse,
              open: true,
              action: 'add',
              message: 'Are you sure you want to add the selected word?'
            })
          }}
        >
          Add
        </button>
        <button
          type="button"
          ref={removeRef}
          className="btn btn-full btn-sm btn-secondary  mb-1 ms-1 font12 w-100"
          onClick={() => {
            // handlewordManupilation(imagesSelected, selectedCategory?.value, selectedReplaceCategory?.value, 'remove')
            setWordManipulationResponse({
              ...wordManipulationResponse,
              open: true,
              action: 'remove',
              message: 'Are you sure you want to remove the selected word?'
            })
          }}
        >
          Remove
        </button>
      </div>
      <div className="d-flex justify-content-between mx-2 mt-2">
        <button
          ref={replaceRef}
          type="button"
          className="btn btn-full btn-sm btn-secondary  mb-1 font12 w-50"
          onClick={() => {
            // handlewordManupilation(imagesSelected, selectedCategory?.value, selectedReplaceCategory?.value, 'replace')
            setWordManipulationResponse({
              ...wordManipulationResponse,
              open: true,
              action: 'replace',
              message: 'Are you sure you want to replace the selected word?'
            })
          }}
        >
          Replace
        </button>
      </div>
      <div className="d-flex flex-column align-items-end mx-2 mt-2">
        <Input
          type="checkbox"
          label="Enable Delete"
          checked={enableDelete}
          onChange={(e) => setEnableDelete(e.target.checked)}
          inputId={'enableDelete'}
        />
       {enableDelete && <button
          type="button"
          className="btn btn-full btn-sm btn-secondary  mb-1 font12 w-100"
          onClick={() => {
            handleDeleteImages(imagesSelected)
          }}
        >
          Delete Selected Images
        </button>}
      </div>
    </div>
  )
}

export default WordManupulationSection
