import toast from 'react-hot-toast';

// TO REDUCE TIME OFFSET FOR TIME DIFF.
function adjustForTimezone(date) {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date
}

export function formatDate(d, format = 'dd/mm/yy', adjustOffset = false) {
  if (!d) {
    return ''
  }
  let monthsArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let date = new Date(d);
  if (adjustOffset) {
    date = adjustForTimezone(new Date(d))
  }
  // console.log(date)
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var h = date.getHours();
  var m = date.getMinutes();
  var timeFormat = 'AM';
  var s = date.getSeconds();
  var ms = date.getMilliseconds();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  //Time 
  if (h <= 11 && m < 60) {
    timeFormat = "AM";
  } else if (h >= 12) {
    timeFormat = "PM";
  }
  if (format.includes('12')) {
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    m = m;
  }
  if (h < 10) {
    h = '0' + h;
  }
  if (m < 10) {
    m = '0' + m;
  }
  if (s < 10) {
    s = '0' + s;
  }
  if (ms < 10) {
    ms = '0' + ms;
  }

  let formatNeed = format?.toLowerCase();

  if (formatNeed === 'dd-mm-yy') {
    return (d = dd + '/' + mm + '/' + yyyy?.toString()?.slice(2, 4));
  } else if (formatNeed === 'dd/mm/yy') {
    return (d = dd + '/' + mm + '/' + yyyy?.toString()?.slice(2, 4));
  } else if (formatNeed === 'dd/mm/yyyy') {
    return (d = dd + '/' + mm + '/' + yyyy);
  } else if (formatNeed === 'dd/mm/yyyy') {
    return (d = dd + '/' + mm + '/' + yyyy);
  } else if (formatNeed === 'dd-mm-yyyy') {
    return (d = dd + '-' + mm + '-' + yyyy);
  } else if (formatNeed === 'yyyy-mm-dd hh:mm:ss.ms') {
    return (d = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + m + ':' + s + '.' + ms);
  } else if (formatNeed === 'yyyy-mm-dd hh:mm:ss') {
    return (d = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + m + ':' + s);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm:ss.ms') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s + '.' + ms);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm:ss') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm 12h') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ' ' + timeFormat);
  } else if (formatNeed === 'yyyy-mm-dd') {
    return (d = yyyy + '-' + mm + '-' + dd);
  } else if (formatNeed === 'yyyy-mm-ddthh:mm') {
    return (d = yyyy + '-' + mm + '-' + dd + 'T' + h + ":" + m);
  } else if (formatNeed === 'yyyy-mm-dd hh:mm 12h') {
    return (d = yyyy + '-' + mm + '-' + dd + ' ' + h + ":" + m + ' ' + timeFormat);
  } else if (formatNeed === 'mon dd yyyy') {
    return (d = monthsArr[mm - 1] + ' ' + dd + ' ' + yyyy);
  } else if (formatNeed === 'iso') {
    return date?.toISOString();
  } else if (formatNeed === 'utc') {
    return date?.toUTCString();
  }
}

export const roundOffValue = (data) => {
  try {
    return Math.round(Number(data));
  } catch (e) {
    return data;
  }
};

export const deleteApiPayload = (value) => {
  try {
    return {
      data: value,
    };
  } catch (e) {
    return {
      data: {},
    };
  }
};

export const handleFileReader = async (event, setFile) => {
  let reader = await new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onload = (e) => {
    setFile({
      data: reader.result.split(',').pop(),
      fileName: event.target.files[0].name,
    });
  };
};

export const getObjectSubset = (obj, ...keys) => Object.fromEntries(
  keys
    .filter(key => key in obj)
    .map(key => [key, obj[key]])
);

export const deleteObjectKeys = (obj, ...keys) => {
  keys.forEach(e => delete obj[e]);
  return obj || null
};

export const findObjectInArray = (arr = [], keyName, matchingKey) => { try { return arr?.find(o => o[keyName] === matchingKey) } catch (e) { return {} } };
export const findObjectIndexInArray = (arr, keyName, keyValue, toLower) => { if(toLower){
  return arr.findIndex(p => p[keyName]?.toLowerCase() === keyValue?.toLowerCase())
}else{
  return arr.findIndex(p => p[keyName] === keyValue)
} };

export const getInitialDataFormik = (arr, key, valueKey) => {
  let data = {}
  if (arr.length) {
    arr.forEach(element => {
      data[element[key]] = element[valueKey] || ""
    });
  }
  return data
}

export const getArrayOfObjJoinToStringForKey = (arr, key, joinKey = ", ") => {
  return arr?.map(u => u[key])?.join(joinKey) || ''
}
export const getArrayOfSignleKeyFromArrOfObj = (arr, key, convertToSting=false) => {
  return arr?.map(u => convertToSting ? ""+ u[key] : u[key]) || []
}

export function humanize(str) {
  try {
    var i, frags = str.toLowerCase()?.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    }
    return frags?.join(' ');
  } catch (e) {  }
}

export function isNumeric(value) {
  return /^\d+$/.test(value);
}
export function getOrderType(value) {
  let label = "N/A"
  if (value === 1)
    label = "Lazada";
  else if (value === 2)
    label = "Shopee";
  else if (value === 3)
    label = "Tiktok Shop";
  else if (value === 4)
    label = "Q100";
  else if (value === 5)
    label = "Shopify";
  return label;
}
export function getFontSize(value) {
  let size = "15px";
  if (value === "large")
    size = "20px";
  else if (value === "small")
    size = "10px";
  else if (value === "standard")
    size = "15px";
  else if (value === "xlarge")
    size = "25px";
  else if (value === "xxlarge")
    size = "30px";
  return size;
}
export function getOrderStatus(value) {
  let label = "N/A"
  if (value === 140)
    label = "Cancelled";
  else if (value === 130)
    label = "Completed";
  else if (value === 122)
    label = "Delivered";
  else if (value === 121)
    label = "In Transit";
  else if (value === 114)
    label = "Partially Shipped";
  else if (value === 111)
    label = "Awaiting Shipment";
  else if (value === 112)
    label = "Awaiting Collection";
  else if (value === 105)
    label = "On Hold";
  else if (value === 100)
    label = "Unpaid";
  return label;
}
export const isObjectEmpty = (objectName) => {
  try {
    return Object.keys(objectName).length === 0 && objectName.constructor === Object;
  } catch (e) {
    return true
  }
}
export function removeExtraSpace(string, type = "") {
  let str = string
  if (type === 'all') {
    str = str.replace(/\s/g, '')
  } else if (type === ' , ') {
    str = str.replace(/\s*,\s*/g, ",")
  } else {
    str = str.replace(/\s+/g, ' ').trim()
  }
  return str
}


// handleParse  and processCSV is used to read and process csv files   https://dev.to/theallegrarr/read-csv-files-in-react-apps-without-installing-any-package-hn7

export const handleParse = (file) => {
  return new Promise((resolve, reject) => {
    try {
      // Initialize a reader which allows user
      // to read any file or blob.
      const reader = new FileReader();
      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = function (e) {
        const text = e.target.result;
        resolve(text)
      }
      reader.readAsText(file);
    } catch (e) { }
  })

};

export const processCSV = (str, delim = ',', dataNeeded = "headers") => {
  const headers = str.slice(0, str.indexOf('\n')).split(delim).map((item) => {
    let itemData = item.replace("\r", "")
    return itemData
  });
  if (dataNeeded === 'headers') {

    return headers
  } else if (dataNeeded === 'csvAsArray') {
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const csvArray = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})
      return eachObject;
    })
    return csvArray
  }
  return []
}

export function validDate(date, compareDate = null, checkEqual = false) {
  // const currentDate = new Date();
  // const incomingDate = new Date(date)
  // let crr = formatDate(currentDate);
  // let incr = formatDate(incomingDate);
  // if(crr == incr) {
  //   return false
  // }else{
  //   let status = currentDate > incomingDate
  //   return status;
  // }
  let current_date = new Date();
  if (compareDate) {
    current_date = new Date(compareDate)
  }
  let incoming_date = new Date(date);
  if (current_date.getTime() < incoming_date.getTime()) {
    return true
  } else if (current_date.getTime() <= incoming_date.getTime() && checkEqual) {
    return true
  } else {
    return false
  }
}


export const crypt = (salt = "crypt", textStr) => {
  let text = String(textStr?.toString())
  const textToChars = (text) => text?.split("").map((c) => c?.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n)?.toString(16))?.substr(-2);
  const applySaltToChar = (code) => textToChars(salt)?.reduce((a, b) => a ^ b, code);
  return text
    ?.split("")
    ?.map(textToChars)
    ?.map(applySaltToChar)
    ?.map(byteHex)
    ?.join("") || "";
};

export const decrypt = (salt = "crypt", encoded) => {
  const textToChars = (text) => text?.split("")?.map((c) => c?.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt)?.reduce((a, b) => a ^ b, code);
  return encoded?.toString()
    ?.match(/.{1,2}/g)
    ?.map((hex) => parseInt(hex, 16))
    ?.map(applySaltToChar)
    ?.map((charCode) => String?.fromCharCode(charCode))
    ?.join("") || "";
};

export function generateArrayOfYears(yearsNeed = 9) {
  var max = new Date().getFullYear()
  var min = max - yearsNeed
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export function generateArrayOfMonths() {
  const month = [
    { value: '01', label: "January" },
    { value: '02', label: "February" },
    { value: '03', label: "March" },
    { value: '04', label: "April" },
    { value: '05', label: "May" },
    { value: '06', label: "June" },
    { value: '07', label: "July" },
    { value: '08', label: "August" },
    { value: '09', label: "September" },
    { value: '10', label: "October" },
    { value: '11', label: "November" },
    { value: '12', label: "December" }
  ];
  return month
}


export const checkPaymentOnline = (data) => {
  let isOnline = false;
  if (data?.payment_type) {
    if (!(data?.payment_type?.toLowerCase()?.includes('bank transfer')
      || data?.payment_type?.toLowerCase()?.includes('cheque')
      // || data?.payment_type?.toLowerCase()?.includes('card')
      || data?.payment_type?.toLowerCase()?.includes('demand draft')
      || data?.payment_type?.toLowerCase()?.includes('draft')
      || data?.payment_type?.toLowerCase()?.includes('demand')
      || data?.payment_type?.toLowerCase()?.includes('neft')
      || data?.payment_type?.toLowerCase()?.includes('rtgs')
      || data?.payment_type?.toLowerCase()?.includes('wt'))) {
      isOnline = true
    } else {
      isOnline = false
    }
  }

  return isOnline
}


export async function fetchFile(url, header = {}, target = "") {
  return new Promise(async (resolve, reject) => {
    try {
      // const res = await fetch(url);
      // const file = await res.blob();
      // let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement("a");
      aTag.href = url;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      // aTag.download = true;
      // aTag.download = url.replace(/^.*[\\\/]/, "");
      document.body.appendChild(aTag);
      aTag.click();
      // URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      reject();
    }
  });
}

export async function fetchInPromiseFile(url, header = {}, target = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url);
      const file = await res.blob();
      let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement("a");
      aTag.href = tempUrl;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      aTag.download = true;
      aTag.download = url.replace(/^.*[\\\/]/, "");
      document.body.appendChild(aTag);
      aTag.click();
      URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      reject();
    }
  });
}

export function downloadBlobFile(data, name = 'File') {
  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.download = name; //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function downloadBlobXLSFile(data, name = 'File') {
  // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.

  try {
    const outputFilename = `${name}.xlsx`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    throw Error(error);
  }
}


export function getParameters() {
  let parameters = window.location.search.replace('?', '');

  let currentParameters = {};

  if (Object.keys(parameters).length) {

    parameters = parameters.split('&');

    for (let i = 0; i < parameters.length; i++) {
      let parameter = parameters[i].split('=');
      if (parameter[0] !== 'tab') {
        currentParameters[parameter[0]] = decrypt(parameter[0], parameter[1].replaceAll('%20', ' ') || "");
      } else {
        currentParameters[parameter[0]] = parameter[1].replaceAll('%20', ' ');
      }
    }
  }

  return currentParameters;
}

export function getNewParameters(newParameters) {
  const parameters = getParameters();
  const parametersKeys = Object.keys(parameters)
  const keys = Object.keys(newParameters);


  for (let i = 0; i < parametersKeys.length; i++) {
    if (!keys.includes(parametersKeys[i]) && parametersKeys[i] !== 'tab') {
      delete parameters[parametersKeys[i]]
    }
  }
  for (let i = 0; i < keys.length; i++) {
    const value = newParameters[keys[i]];
    parameters[keys[i]] = value

    if (!value) {
      delete parameters[keys[i]];
    }
  }
  let newUrl = updateUrl(parameters)

  return { parameters, ...newUrl };
}

export function updateUrl(parameters) {
  let search = '';
  let j = 0;
  let separator = '?';

  Object.keys(parameters).forEach((key) => {

    let value = parameters[key];

    if (value) {

      if (j !== 0) {
        separator = '&';
      }
      if (key !== 'tab') {
        search += `${separator}${key}=${crypt(key, value || "")}`;
      } else {
        search += `${separator}${key}=${value}`;
      }
      j++;
    }
  });

  let newUrl = `${window.location.pathname}${search}`;

  // prevents pushing same url if function won't change url.
  if (window.location.href !== newUrl) {
    return { newUrl, search }
    // history.pushState(null, null, newUrl);
  }
}

export const generateNewQueryParams = (obj, type = "string", startWith = "?") => {
  try {
    if (obj) {
      if (type === 'string') {
        return '?' + new URLSearchParams(obj)?.toString();
      } else {
        return new URLSearchParams(obj);
      }
    }
  } catch (e) { }
}


export function isJson(str) {
  let parsed = str
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    return { status: false, data: null };
  }
  return { status: true, data: parsed };
}

export const createCommonSettingCategoryTableData = (data, parentKey) => {
  let parsedData = isJson(data)
  if (parsedData?.status) {
    let newData = parsedData.data
    let keys = Object.keys(newData)
    let updatedData = keys.map((item, index) => {
      return {
        'parent_key': parentKey,
        'key_name': item,
        'key_value': newData[item]
      }
    })
    return updatedData
  }
  return []
}

export const copyTextToClipBoard = (copyValue) => {
  navigator.clipboard.writeText(copyValue);
};


export const gradientWithBgImage = (image, gradient) => {
  let gradientValue = gradient ? gradient : '88.65deg, #0A2640 19.01%, rgba(10, 38, 64, 0) 100.83%'
  return `linear-gradient(${gradientValue}), url(${image})`
};

export const buildTree = (data) => {
  const root = [];
  const indexedData = {};

  if (!data || data == null || !data.length) {
    return []
  }

  // Create an index of items by their id
  data?.forEach((item) => {
    indexedData[item.id] = {
      ...item,
      label: item.local_display_name,
      value: item.id,
      children: []
    };
  });

  data?.forEach((item) => {
    if (item.parent_id === "0") {
      root.push(indexedData[item.id]);
    } else {
      indexedData[item.parent_id].children.push(indexedData[item.id]);
    }
  });

  return root;
}

export const getFileNameFromStringS3 = (data) => {
  try {
    if (data) {
      let imageName
      imageName = data?.split("/")
      imageName = imageName[imageName?.length - 1]
      return imageName
    }
    return data
  } catch (e) {
    return data
  }
}

export function getImageAsBase64(input) {
  return new Promise((resolve, reject) => {
    // Regular expression pattern for validating URLs
    const urlPattern = /^(http|https):\/\/[a-zA-Z0-9./-]+$/;

    if (typeof input === 'string') {
      if (urlPattern.test(input)) {
        // If the input is a URL, fetch the image
        fetch(input)
          .then((response) => response.blob())
          .then((blob) => {
            if (blob.type.startsWith('image/')) {
              const reader = new FileReader();
              reader.onload = (event) => {
                resolve({ base64: event.target.result, fileName: blob.name || 'image.jpg' });
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(blob);
            } else {
              reject(new Error('The URL does not point to a valid image.'));
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('Invalid URL format.'));
      }
    } else if (input instanceof File || (input instanceof Blob && input.type.startsWith('image/'))) {
      // If the input is a File object or a Blob with an image MIME type
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve({ base64: event.target.result, fileName: input.name });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(input);
    } else {
      reject(new Error('Unsupported input type.'));
    }
  });
}

export function removeEmptyKeyFromNestedObj(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmptyKeyFromNestedObj(v) : v }),
      {}
    );
}

export function removeEmptyKeyFromObj(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}


export const splitInput = (input) => {
  const regex = /\+\.{1,7}(?:\s+|$)/g;
  return input.split(regex);
};


 
// Count the number of dots in the first index of each split output
export const countDotsInFirstIndex = (parts) => {
  if (parts.length > 0) {
    const firstSegment = parts[1];
    const dotCount = (firstSegment.match(/\./g) || []).length;
    return dotCount;
  }
  return 0; // Return 0 if there are no segments
};

export const validatePhoneNumAccCountry = (phoneNumber)=> {
  if(!phoneNumber) return 0;
  let splittedFormat = splitInput(phoneNumber);
  let dotCount = countDotsInFirstIndex(splittedFormat);

  return dotCount || 0;
}
 

export function onlyUnique(array=[]) {
  try{
    let newArr = array?.map((el, index, arr)=>{
      return el?.trim()
    })
    newArr = newArr.filter(n => n)
    return newArr?.filter((el, index, arr)=>{
      return arr?.indexOf(el) === index;
    })
  }catch(e){}
}

export const showToast = (type="success", message="---") => {
  if(type === 'success'){
    toast.success(message);
  } else if (type === 'error') {
    toast.error(message);
  }
}

export const computeLoaderState = (stateObj) => {
  let status = false
  status = Object.keys(stateObj).some(k => stateObj[k]);
  return status
}