import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getShootFolders = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.shootList}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const getRangeImagelistFolders = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.rangeimagelist}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const getAllKeywordsFolders = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.allkeywords}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const addRemoveMultiKeywords = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.multiaddremove}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const advanceSearch = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.advanceSearch}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const wordManupilation = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.wordmanupilation}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const deleteSelectedImages = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.deleteimages}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      return false
    }
};