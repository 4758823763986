export const menuItemsData = [
    {
        title: 'Database',
        url: '/',
    },
    {
      title: 'Relationship',
      url: '/',
      submenu: [
        {
          title: 'Web Design',
          url: '',
        },
        {
          title: 'Web Development',
          url: '',
        },
        {
          title: 'SEO',
          url: '',
        },
      ],
    },
    {
        title: 'Image Keywording',
        url: '/',
    },
    {
        title: 'Search',
        url: '/',
    },
    {
        title: 'Advance Search',
        url: '/',
    },
  ];