import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getCoExistanceList = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.coExistanceList}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};

export const createCoExistance = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.createCoExistance}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};