import React from "react"

const Input = React.forwardRef(({
  label = null,
  labelPlacement = "left",
  labelProps={},
  innerContainerProps = {},
  mainContainerProps = {},
  children,
  type="input",
  disableSwitch=true,
  inputId="",
  ...props
}, ref) => {
  return (
    <div {...mainContainerProps} className={` ${type === 'checkbox' ? `form-check ${disableSwitch ? '' : 'form-switch'}`: "d-flex"} ${mainContainerProps?.className}`} >
      {label && labelPlacement === "left" && (
        <label
          className={`col-form-label me-2 size-12 ${type === 'checkbox' ? "form-check-label" : ""}`}
          style={{ lineHeight: 1, ...labelProps?.style }} for={inputId}
          {...labelProps}
        >
          {label}
        </label>
      )}
      {type === 'checkbox' ?  <input class="form-check-input" type="checkbox" id={inputId} {...props}/>
         : <div className="" style={{ flex: 1 }} {...innerContainerProps}>
        {type === 'textarea' ? <textarea rows="4" ref={ref}
         className="form-control rounded-0 size-12"
         style={{ lineHeight: 1, height: 30, ...props?.style }}
         id={inputId}
         {...props}
        ></textarea> : 
          <input
            ref={ref}
          type="text"
          className="form-control rounded-0 size-12"
          style={{ lineHeight: 1, height: 30, ...props?.style }}
          id={inputId}
          {...props}
        />}
      </div>}
      {label && labelPlacement === "right" && (
        <label
          className={`col-form-label me-2 size-12 ${type === 'checkbox' ? "form-check-label" : ""}`}
          style={{ lineHeight: 1 }}
          for={inputId}
          {...labelProps}
        >
          {label}
        </label>
      )}
      {children&& children}
    </div>
  )
})

export default Input
