const currEnv = process.env.REACT_APP_ENV;
const apiUrl = currEnv === "dev" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL;
const baseUrl = apiUrl;

const BASE_URL_MAIN = `${baseUrl}/`;
const S3_BASE_URL = process.env.REACT_APP_S3_URL;
const REACT_APP_SALT = process.env.REACT_APP_SALT;

let ApiConfig = {
  token: null,
  S3_BASE_URL,
  REACT_APP_SALT,

  //keywords api
  getKeywords: `${BASE_URL_MAIN}admin/getKeywords`,
  visibleKeyword: `${BASE_URL_MAIN}keyword/visiblekeywordsearch`,
  
  categoryList: `${BASE_URL_MAIN}category/categoryList`,
  categorykeywordlist: `${BASE_URL_MAIN}category/categorykeywordlist`,
  imagekeywords: `${BASE_URL_MAIN}images/imagekeywords`,

  //Search screen
  shootList: `${BASE_URL_MAIN}images/shootList`,
  rangeimagelist: `${BASE_URL_MAIN}images/rangeimagelist`,
  allkeywords: `${BASE_URL_MAIN}images/allkeywords`,
  multiaddremove: `${BASE_URL_MAIN}images/multiaddremove`,
  deleteimages: `${BASE_URL_MAIN}images/deleteimages`,
  advanceSearch: `${BASE_URL_MAIN}keyword/advancesearch`,
  wordmanupilation: `${BASE_URL_MAIN}images/wordmanupilation`,

  // Enter New Keyword
  keywordsCateogyListing: `${BASE_URL_MAIN}category/keywordsCateogylisting`,
  createKeyword: `${BASE_URL_MAIN}keyword/createKeyword`,
  editKeyword: `${BASE_URL_MAIN}keyword/editKeyword`,
  deleteKeyword: `${BASE_URL_MAIN}keyword/deleteKeyword`,
  
  //Relationship 
  nonRelatedList: `${BASE_URL_MAIN}keyword/nonrelatedlist`,
  searchChild: `${BASE_URL_MAIN}category/searchChild`,
  updateChild: `${BASE_URL_MAIN}category/updateChild`,
  
  //Rules 
  coExistanceList: `${BASE_URL_MAIN}keyword/coexistancelist`,
  createCoExistance: `${BASE_URL_MAIN}keyword/createcoexistance`,
  
  
  //Data Export 
  exportImages: `${BASE_URL_MAIN}images/exportimages`,

};

export default ApiConfig;
