import React, { useState } from 'react'
import Select from '../CommonComp/Select'
import Input from '../CommonComp/Input'
import { onlyUnique } from '../../lib/helper'

const classifications = [
    {label: 'Keywords', value: 'keywords'},
    {label: 'Main Category', value: 'main_category'},
    {label: 'General Category', value: 'general_category'},
]

function ClassificationSection({categoriesData=[], selectedClassification=null, enteredKeyword='', hiddenKeyword='',
  setEnteredKeyword=()=>{}, setHiddenKeyword=()=>{}, setSelectedClassification=()=>{}, editMode=false
}) {
    const autoFormat = () =>{
        let removedSpecailChar;
        let removedSpaceAddComa;
        removedSpecailChar = enteredKeyword.replace(/[^\w\s,]/g, '').replace(/,{2,}/g, ',').replace(/^,|,$/g, '');
        let enteredKeywordFormatted = removedSpecailChar;
        setEnteredKeyword(enteredKeywordFormatted)
        removedSpecailChar = hiddenKeyword.replace(/[^\w\s,]/g, '').replace(/,{2,}/g, ',').replace(/^,|,$/g, '');
        removedSpaceAddComa = removedSpecailChar.replace(/\s+/g, ',').replace(/,{2,}/g, ',')
        let hiddenKeywordFormatted = removedSpaceAddComa;
        removedSpaceAddComa = enteredKeywordFormatted.replace(/\s+/g, ',').replace(/,{2,}/g, ',');
        hiddenKeywordFormatted = removedSpaceAddComa.concat(",", hiddenKeywordFormatted);
        let wordArray = hiddenKeywordFormatted?.split(/,\s*|,/);
        hiddenKeywordFormatted = onlyUnique(wordArray)?.join(",");
        setHiddenKeyword(hiddenKeywordFormatted);
    }
    return (
        <div className='border-1 d-flex flex-column' style={{flex:1}}> 
            <div className="m-2 my-1 d-flex flex-column">
                <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Classification</div>
                <div>
                    <Select 
                        options={categoriesData} 
                        mainContainerProps={{
                            className: 'd-flex m-0 ms-1'
                        }}
                        isDisabled={editMode}
                        value={selectedClassification}
                        onChange={setSelectedClassification}
                        isSearchable={true}
                    />
                </div>
            </div>
            <div className="m-2 my-1 d-flex flex-column mt-3">
                <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Keywords</div>
                <div>
                    <Input
                        value={enteredKeyword}
                        id="keyword-input"
                        placeholder="Keywords"
                        mainContainerProps={{
                            className: "d-flex mb-3 position-relative",
                        }}
                        onChange={(e) => setEnteredKeyword(e?.target?.value?.replace(/\b\w/g, match => match.toUpperCase()))}
                        labelProps={{style:{
                            width: 125
                        }}}
                        autoComplete="off"
                    />
                </div>
            </div>
            <div className="m-2 my-1 d-flex flex-column" style={{flex: 1}}>
                <div className='d-flex align-items-center'>
                    <div className="font12 fontWeightBold mb-1" style={{ flex: 1 }}>Hidden Keywords</div>
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary mb-2 font12"
                        onClick={autoFormat}
                    >
                        Auto Formatting
                    </button>
                </div>
                <div className=" d-flex flex-column" style={{flex: 1}}>
                    <Input
                        value={hiddenKeyword}
                        id="keyword-input"
                        type="textarea"
                        placeholder="Keywords"
                        mainContainerProps={{
                            className: "position-relative",
                            style:{flex: 1}
                        }}
                        style={{
                            height: '100%',
                            maxHeight: 200
                        }}
                        rows="4"
                        onChange={(e) => setHiddenKeyword(e?.target?.value)}
                        labelProps={{style:{
                            width: 125
                        }}}
                        autoComplete="off"
                    />
                </div>
            </div>
        </div>
    )
}

export default ClassificationSection