import React from "react"

function MessageDialog({
  messageHeading = "Message",
  message = "",
  okClick = null,
  cancelClick = null,
}) {
  return (
    <div
      style={{
        background: "#01010154",
        position: "absolute",
        display: "flex",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ maxWidth: 400 }}>
        <div
          style={{
            background: "#fff",
            maxWidth: "fit-content",
          }}
        >
          <div
            style={{
              background: "var(--color-4)",
              padding: 10,
              color: "black",
              fontWeight: "bold"
            }}
            className="d-flex justify-content-between align-items-center"
          >
            {messageHeading} 
            {/* <span>X</span> */}
          </div>
          <div style={{ background: "#fff", color: "black", padding: 10 }}>
            {message}
          </div>
          <div
            style={{ background: "#fff" }}
            className="d-flex justify-content-end align-items-center pt-3 ps-3 pe-2 pb-2"
          >
            {okClick && (
              <button
                type="button"
                className="btn btn-sm btn-secondary ms-3"
                onClick={() => {
                  okClick()
                }}
              >
                Okay
              </button>
            )}
            {cancelClick && (
              <button
                type="button"
                className="btn btn-sm btn-secondary ms-3"
                onClick={() => {
                  cancelClick()
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageDialog
