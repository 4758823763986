import axios from 'axios';
import ApiConfig from '../config/ApiConfig';

export const getSearchCategoryChildData = async (payload={}) => {
    try {
      const resp = await axios.post(`${ApiConfig.searchChild}`, payload);
      if (
        resp?.data?.status >= 200 &&
        resp?.data?.status < 400
      ) {
        return resp?.data?.data
      } else {
        throw resp;
      }
    } catch (e) {
      return []
    }
};


export const updateChildKeyword= async (payload) => {
    if(payload){
      try {
        const resp = await axios.post(`${ApiConfig.updateChild}`, payload);
        if (
          resp?.data?.status >= 200 &&
          resp?.data?.status < 400
        ) {
          return true
        } else {
          throw resp;
        }
      } catch (e) {
        return false
      }
    }
};