import React, { useEffect, useState, useRef } from "react"
import Input from "../CommonComp/Input"
import { addRemoveMultiKeywords } from "../../services/SearchScreenApis"
import Select from "../CommonComp/Select"

function  FiltersSection({
    checkbox = {},
    handleCheckBox = () => {},
    actualWordInput = "",
    setActualWordInput = () => {},
    handleFilterSubmit = () => {},
    handleMultiplePopupData = () => {},
    setFetchKeywordsStatus = () => {},
    multiplePopupList=[],
    setMultiplePopupList = () => {},
    isMaximized=false,
    imagesSelected=[],
    loading={}, setLoading=()=>{},
    allKeywordForSelect=[],
    multiAddResponse,
    setMultiAddResponse,
    multiRemoveResponse,
    setMultiRemoveResponse,
    filterFunction = () => {}
}) {
  const [position, setPosition] = useState({
    positionTop: 0,
    positionLeft: 0,
    positionWidth: 0,
    positionHeight: 0,
  })
  const selectRef = useRef(null);
  const multiAddRef = useRef(null);
  const multiRemoveRef = useRef(null);
  useEffect(()=>{
    if(multiAddResponse?.confirm == true){
      setMultiAddResponse({
        open: false,
        confirm: false
      });
      handleMultiAddRemove(imagesSelected, multiplePopupList, 'add');
      // selectRef.current.focus();
      multiAddRef.current.focus();
    }
    if(multiRemoveResponse?.confirm == true){
      setMultiRemoveResponse({
        open: false,
        confirm: false
      });
      handleMultiAddRemove(imagesSelected, multiplePopupList, 'remove');
      // selectRef.current.focus();
      multiRemoveRef.current.focus();
    }
  },[multiAddResponse.confirm, multiRemoveResponse.confirm])

  const handleMultiAddRemove = async (imageIds=[], keywords=[], action=null) => {
    setLoading({
      ...loading,
      submit: true
    })
    
    
    if(imageIds?.length && keywords.length && action){
      let keywordList = keywords.map((item)=>(item));
      let data = await addRemoveMultiKeywords({
        image: imageIds?.join(','),
        keyword: keywordList?.join(','),
        action: action
      })
      setFetchKeywordsStatus({
        status: true,
        from: "normal"
      })
    }
    setLoading({
      ...loading,
      submit: false
    })
  }

  useEffect(()=>{
     var targetElement = document.getElementById('multipleCheck');
     var targetElementParent = document.getElementById('multiFilterParent');
     if(targetElement){
       var rect = targetElement.getBoundingClientRect();
       var rect1 = targetElementParent.getBoundingClientRect();
       setPosition({
          positionTop: rect.top,
          positionLeft: rect.left,
          positionWidth: rect1.width,
          positionHeight: rect1.height,
       })
      }
  },[isMaximized])

  const [open, setOpen] = useState(false);

  const removeFromList = (keyword) =>{
    let listCopy = multiplePopupList;
    let keywordIndex = listCopy.indexOf(keyword);
    if(keywordIndex > -1){
      listCopy.splice(keywordIndex, 1);
      setMultiplePopupList([...listCopy]);
    }
  }

  const handleClick = (e, index) => {
    if (e.detail % 2 !== 0) {
      let selectedList = document.getElementsByClassName("multiplePopupListItem");
      if (selectedList?.length) {
        for (var i = 0; i < selectedList?.length; i++) {
          selectedList[i].classList.remove("focused-li")
        }
      }
      e.target.classList.add("focused-li");
    } else if (e.detail % 2 === 0) {
      removeFromList(e.target.innerHTML);
    }
  }
  let [enterkeyPressed, setEnterKeyPressed] = useState(0);
  const runOnEnter = (e) => {
    if (e.key == 'Enter') {
      let enterKeyCount = enterkeyPressed;
      enterKeyCount++;
      setEnterKeyPressed(enterKeyCount);
    }else if(e.key == 'c' && (e.ctrlKey)){
      copyToClipboard(actualWordInput.value);
    }

  };
  const copyToClipboard = (itemToCopy) =>{
    navigator.clipboard.writeText(itemToCopy).then(()=>{
      showSimpleToast(itemToCopy);
    },()=>{
      alert("Copying to clipboard failed. Please try again.");
    });
  }
  const showSimpleToast = (copiedText) =>{
    let toastElement = document.createElement('p');  
    toastElement.id = 'copiedTextToast';
    toastElement.classList.add('px-5', 'py-3', 'rounded', 'bg-dark');
    toastElement.style.cssText += 'position: fixed; z-index: 2; color: #fff; top: 10px;left: 10px;';
    toastElement.innerHTML = 'Value copied: "' + copiedText + '"';
    document.body.appendChild(toastElement);
    setTimeout(() => {
      let element = document.getElementById('copiedTextToast');
      element.remove();
    }, 2000);
  }
  useEffect(()=>{
    if(enterkeyPressed == 1){

        if(checkbox?.multipleKeywordList){
          handleMultiplePopupData([actualWordInput.value], 'insert', checkbox);
        }
        selectRef.current.focus();
        setEnterKeyPressed(0);
    }
  },[enterkeyPressed]);

  return (
    <div style={{ background: "var(--color-4)" }} className="py-1 m-1" id="multiFilterParent">
      {/* <div className="font13 ms-1 fontWeightBold">
        Filter Images In Selected Folder
      </div> */}
      <div className="m-2 my-1 d-flex align-items-center">
        <div className="font12 fontWeightBold" style={{ flex: 1 }}>Actual Words</div>
        <div>
            <Input
                type="checkbox"
                label="Not Exist"
                checked={checkbox?.notExist}
                onChange={(e) => handleCheckBox(e, 1)}
                inputId={"notExist"}
            />
        </div>
      </div>
      <div className="m-2 position-relative">
        <div className="font12" style={{ flex: 1 }}>
            {/* <Input
                value={actualWordInput}
                onChange={(e) => {
                    setActualWordInput(e.target.value)
                }}
                onKeyDown={(e)=>{
                  if(e.key === 'Enter' && checkbox?.multipleKeywordList){
                    handleMultiplePopupData(actualWordInput, 'insert', checkbox)
                  }
                }}
                mainContainerProps={{
                    className: 'mb-1'
                }}
            /> */}
            <div className="custom-select-height">
              <Select
                options={allKeywordForSelect}
                labelProps={{
                  style: {
                    width: 125,
                  },
                }}
                value={actualWordInput}
                onChange={(e) => {
                  setActualWordInput(e)
                }}
                mainContainerProps={{
                  className: "mb-3",
                }}
                isSearchable={true}
                isClearable
                onKeyDown={(e) => {
                  runOnEnter(e);
                }}
                ref={selectRef}
                filterOption={filterFunction}
              />
            </div>
        </div>
        <div className="d-flex justify-content-between" id="multipleCheck">
            <Input
                type="checkbox"
                label="Multiple Keyword List"
                checked={checkbox?.multipleKeywordList}
                onChange={(e) => handleCheckBox(e, 2)}
                inputId={"multiKeywordList"}
            />
            {checkbox?.multipleKeywordList && <button
              type="button"
              className="btn btn-sm btn-secondary font12"
              onClick={() =>   handleMultiplePopupData([actualWordInput.value], 'insert', checkbox)}
            >Add</button>}
        </div>
        {checkbox?.multipleKeywordList && <div style={{
            position: 'fixed',
            top: position?.positionTop - position?.positionHeight/2,
            left: position?.positionLeft + position?.positionWidth, 
            background: "var(--color-4)", 
            zIndex:1,
            height: 350,
            minWidth: 200,
            overflow: 'hidden',
            webkitBoxShadow: '0px 0px 38px -1px rgba(0,0,0,0.39)',
            mozBoxShadow: '0px 0px 38px -1px rgba(0,0,0,0.39)',
            boxShadow: '0px 0px 38px -1px rgba(0,0,0,0.39)',
            padding: '5px',
            border: '2px solid grey',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div className="font13 text-center fontWeightBold">Multiple Keyword List</div>
            <div className="d-flex justify-content-center">
              <Input
                  type="checkbox"
                  label="And"
                  checked={checkbox?.popupAnd}
                  onChange={(e) => handleCheckBox(e, 3)}
                  mainContainerProps={{
                    className: 'mx-2'
                  }}
                  inputId={"andCheck"}
              />
              <Input
                  type="checkbox"
                  label="Or"
                  checked={checkbox?.paopupOr}
                  onChange={(e) => handleCheckBox(e, 4)}
                  mainContainerProps={{
                    className: 'mx-2'
                  }}
                  inputId={"orCheck"}
              />
            </div>
            <div style={{background: '#fff', flex: 1, overflow: 'hidden', overflowY: 'auto', padding: '5px'}}>
              {multiplePopupList?.map((el, index)=>{
                return <div onClick={(e)=>{handleClick(e, index)}} className="font12 fontWeightBold multiplePopupListItem">{el}</div>
              })}
            </div>
            <div className="d-flex justify-content-between mt-2">
              <button
                type="button"
                className="btn btn-sm btn-secondary mb-1 font12"
                onClick={() => handleMultiplePopupData('', 'clear', checkbox)}
              >
                Clear List
              </button>
              <button
                type="button"
                className="btn btn-sm btn-secondary ms-2 mb-1 font12"
                onClick={() => {
                  // handleMultiplePopupData('', 'clear', checkbox)
                  handleCheckBox({target:{checked: false}}, 2)
                }}
              >
                Hide List
              </button>
            </div>
        </div>}
      </div>
     
      <div className="mx-2">
        <button
          type="button"
          className="btn btn-full btn-sm btn-secondary  mb-1 font12 w-100"
          onClick={() => handleFilterSubmit(checkbox)}
        >
          Filter Images In Folder
        </button>
      </div>
      <div className="d-flex justify-content-between mx-2 mt-1">
        <button
          type="button"
          ref={multiAddRef}
          className="btn btn-full btn-sm btn-secondary  mb-1 font12"
          onClick={() => {
            setMultiAddResponse({
              ...multiAddResponse,
              open: true,
            })
          }}
          >
          Multi Add
        </button>
        <button
          type="button"
          className="btn btn-full btn-sm btn-secondary  mb-1 font12"
          ref={multiRemoveRef}
          onClick={()=>{
            setMultiRemoveResponse({
              ...multiRemoveResponse,
              open:true,
            })
          }}
        >
          Multi Remove
        </button>
      </div>
      
    </div>
  )
}

export default FiltersSection
