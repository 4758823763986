import React, { useEffect } from "react"
import Select from "../CommonComp/Select"
import { useAppContext } from "../../context/AppContext"
import Input from "../CommonComp/Input"

function SearchSection({
  categoriesData = [],
  resetData = false,
  setResetData = () => {},
  selectedCategory = null,
  showAssociateOnly = false,
  handleCheckBox = () => {},
  handleCategoryChange = () => {},
  saveChanges = () => {},
  isMaximized,
  parentDimensions,
}) {

  const { handleMenu } = useAppContext()
  useEffect(() => {
    if (resetData) {
      setResetData(false)
    }
  }, [resetData])

  return (
    <div className="row">
      <div className="col-sm">
        <div className="d-flex flex-column">
            <div className="font12 fontWeightBold mb-1 d-flex justify-content-between" style={{ flex: 1 }}>
              Select Keyword
              <Input
                type="checkbox"
                label="Show Only Associated"
                mainContainerProps={{
                  style:{
                    minHeight: 'auto'
                  }
                }}
                labelProps={{
                  style:{
                    padding: '0px'
                  }
                }}
                checked={showAssociateOnly}
                onChange={(e) => handleCheckBox(e.target.checked)}
              />
            </div>
            <div>
              <Select
                options={categoriesData}
                labelProps={{
                  style: {
                    width: 125,
                  },
                }}
                value={selectedCategory}
                onChange={handleCategoryChange}
                mainContainerProps={{
                  className: "mb-3",
                }}
                isSearchable={true}
                isClearable
              />
            </div>
        </div>
      
      </div>
      <div className="col-3">
        <div className=" d-flex  justify-content-end align-items-end ps-5">
          <button
            type="submit"
            className="btn btn-sm btn-secondary w-50 me-3"
            onClick={saveChanges}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary  w-50"
            onClick={() => {
              handleMenu(6, false)
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default SearchSection
