import React, { useEffect, useRef, useState } from "react"
import RenderCategory from "../CommonComp/RenderCategory"

function AdvanceSearch({
  advanceSearchData,
  isMaximized,
  parentDimensions,
  handleClose,
  handleSelectedAdvanceKeyword=()=>{},
  selectedAdvanceKeyword=null,
  setSelectedAdvanceKeyword=()=>{}
}) {
  return (
    <>
      <div style={{
            background: 'var(--color-4)',
            maxHeight: 400
        }} className='m-1 p-1'>
       
        <RenderCategory
          categories={advanceSearchData}
          selected={selectedAdvanceKeyword}
          labelHeading="Advance Search"
          onClick={(el, index, ref, e) => {
            handleSelectedAdvanceKeyword(el)
          }}
          parentStyle={{height: 250}}
          rowDisabled
          objAccessKey={"label"}
          accessKeyValue={'keyword'}
          id="advance-search"
          isMaximized={isMaximized}
          parentDimensions={parentDimensions}
          virtuosoStyle={{
            background: '#fff'
          }}
        />
        <button
            type="button"
            className="btn btn-sm btn-secondary me-1 mt-2 font12"
            onClick={()=>{handleClose()}}
        >Close</button>
      </div>
    </>
  )
}

export default AdvanceSearch
